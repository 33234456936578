import { useState, useEffect, useContext } from "react";
import axios from "../api/axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLanguageContext } from "../context/languageContext";
import { UserContextProvider } from "../context/UserContextProvider";
import { LootContextProvider } from "../context/LootContextProvider";
import { CoinContextProvider } from "../context/CoinContextProvider";
import LastLoot from "./LastLoot"
import toast from "react-hot-toast";
import Login from "./Login";
import TryAgainIcon from "../assets/svg/TryAgainIcon";
import Loading from "./Loading";

const CaseOpening = () => {
    const location = useLocation();
    const pageLink = useParams();
    const axiosPrivate = useAxiosPrivate();
    const { t } = useLanguageContext();
    const { userData, updateUserData } = useContext(UserContextProvider);
    const { updateLootData } = useContext(LootContextProvider);
    const { coinData } = useContext(CoinContextProvider);

    const currentLocationPath = location.pathname || "/";
    const [slug, setSlug] = useState();
    const [cases, setCases] = useState();
    const [coins, setCoins] = useState();
    const [openingTimes, setOpeningTimes] = useState(1);
    const [totalPrice, setTotalPrice] = useState(null);
    const [lootItemsToSpin, setLootItemsToSpin] = useState();
    const [loot, setLoot] = useState();
    const [isOpening, setIsOpening] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

    const timesOptions = [1, 2, 3, 4, 5, 10];
    const maxLootItemsToSpinAnimation = 30;
    const spinningAnimationTimeSec = 5 + 1;

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        getCase();
        getCoins();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const getCase = async () => {
        try {
            const response = await axios.get(`/fetchcases/${pageLink.slug}`);
            setCases(response.data);
            setTotalPrice(response.data.priceWithDiscount.$numberDecimal)
            setSlug(pageLink.slug);
        } catch (err) {
            console.error(err);
        }
    }

    const getCoins = async () => {
        try {
            const response = await axios.get(`/fetchcoins/`);
            setCoins(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    const openCase = async (isOpenWithAnimation) => {
        if (!userData) {
            setIsLoginModalVisible(true);
            return;
        } else {
            try {
                setIsLoading(true);
                setIsOpening(true);

                const loots = {
                    lootedCoins: [],
                    lootedCoinsAmounts: [],
                };

                // This implementation was made instead server looping
                // Consistency of calls and data structure here was made to be compatible with server side loop version
                const requests = Array.from({ length: openingTimes }, () =>
                    axiosPrivate.put('/cases/open', JSON.stringify({ slug, openingTimes: 1 }), {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                    })
                );

                const responses = await Promise.all(requests);

                responses.forEach((response) => {
                    const data = response?.data;
                    loots.lootedCoins.push(...data.lootedCoins);
                    loots.lootedCoinsAmounts.push(...data.lootedCoinsAmounts);
                });

                setLoot(loots);

                const preparedLootItems = prepareLootItemsToSpin(cases.coins);
                const finalLootItems = insertWinnedLootToSpinningItems(preparedLootItems, loots);
                setLootItemsToSpin(finalLootItems);

                setOpeningTimes('');
                setTotalPrice('');
                getCase();
                if (isOpenWithAnimation) {
                    openWithAnimation();
                } else {
                    setIsOpening(false);
                    setSuccess(true);
                }
            } catch (err) {
                setIsOpening(false);
                if (!err?.response) {
                    toast.error('No Server Response');
                } else if (err.response?.status === 400) {
                    toast.error(`${JSON.stringify(err.response?.data.message).replace(/"/g, '')}`);
                } else {
                    toast.error('Case opening failed. Please contact support.');
                }
                return;
            } finally {
                setIsLoading(false);
            }
        }
    }

    const chooseOpeningTimes = (times) => {
        setOpeningTimes(times);
        setTotalPrice((cases.priceWithDiscount.$numberDecimal * times).toFixed(2))
    };

    const openWithAnimation = () => {
        setTimeout(() => {
            setIsOpening(false);
            setSuccess(true);
        }, spinningAnimationTimeSec * 1000);
    }

    const prepareLootItemsToSpin = (caseLootList) => {
        const preparedLootItems = [];
        let countCases = 0;
        while (countCases < openingTimes) {
            let uniqueRandomLootArray = [];
            let countLootInCases = 0;
            while (countLootInCases < maxLootItemsToSpinAnimation) {
                const randomInteger = Math.floor(Math.random() * caseLootList.length);
                uniqueRandomLootArray.push(caseLootList[randomInteger]);
                countLootInCases++;
            }
            preparedLootItems.push(uniqueRandomLootArray);
            countCases++;
        }
        return preparedLootItems;
    };

    const insertWinnedLootToSpinningItems = (preparedLoot, winnedLoot) => {
        let finalLootItems = preparedLoot;
        let countCases = 0;
        while (countCases < openingTimes) {
            const newItem = {
                amount: { $numberDecimal: String(winnedLoot.lootedCoinsAmounts[countCases]) },
                coin: winnedLoot.lootedCoins[countCases]
            };
            finalLootItems[countCases][24] = newItem;
            countCases++;
        }
        return finalLootItems;
    }

    const handleShowLoginModal = (boolean) => {
        setIsLoginModalVisible(boolean);
    };

    const handleBackToThisCase = () => {
        setOpeningTimes(1);
        setSuccess(false);
        updateUserAndLootData();
    };

    const updateUserAndLootData = () => {
        updateUserData();
        updateLootData();
    }

    return (
        <div className="cases-opening-background">
            <Login isLoginModalVisible={isLoginModalVisible} handleShowLoginModal={handleShowLoginModal} currentLocationPath={currentLocationPath} />
            <LastLoot />
            {success && cases ? (
                <div className="cases-opening flex column center">
                    <h1>{t("Заголовок 8: Открытие кейсов")} "{cases.name}"</h1>
                    <div className="case-loots result flex wrap">
                        {loot && loot.lootedCoins.map((coin, index) => {
                            const symbolOfCoin = coinData.find(({ symbol }) => symbol === coin);
                            const coinColor = symbolOfCoin ? symbolOfCoin.hexColor : '#fff'; // default color
                            return (
                                <div className="case-loot-item flex column center"
                                    key={index}
                                    style={{ borderBottom: `5px solid ${coinColor}` }}
                                >
                                    <img src={`/images/logos/${coin.toLowerCase()}.png`} />
                                    <p>{coin}</p>
                                    <p><b>${loot.lootedCoinsAmounts[index]}</b></p>
                                    <div
                                        className="gradient-overlay"
                                        style={{
                                            background: `linear-gradient(to bottom, #232332 0%, ${coinColor} 100%)`,
                                        }}
                                    ></div>
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className="after-case-opening-buttons flex row">
                        <Link to="/cases" onClick={updateUserAndLootData}><button>{t("Кнопка 4: Открытие кейсов")}</button></Link>
                        <button className="try-again" onClick={handleBackToThisCase}><TryAgainIcon />{t("Кнопка 5: Открытие кейсов")}</button>
                        <Link to="/me"><button>{t("Кнопка 6: Открытие кейсов")}</button></Link>
                    </div>
                </div>
            ) : (
                <>
                    {cases ? (
                        <div className="cases-opening flex column center">
                            <h1>{t("Заголовок 7: Открытие кейсов")} "{cases.name}"</h1>
                            {isOpening ? (
                                isLoading ? (
                                    <Loading />
                                ) : (
                                    <div className="spinner flex column">
                                        <img className="target-arrow" src="/img/spinner/target-arrow.png" />
                                        <div className="top-lines-part flex row">
                                            {[...Array(23)].map((_, index) => (
                                                <div key={index} className={`line ${index % 4 === 3 ? 'bold' : ''}`}></div>
                                            ))}
                                        </div>
                                        <div className="loot-items-part">
                                            {lootItemsToSpin &&
                                                coins &&
                                                coinData &&
                                                lootItemsToSpin.map((innerArray, i) => (
                                                    <div className={`case-loots spinner ${lootItemsToSpin.length > 1 ? 'multiple' : ''} flex row`} key={i}>
                                                        {innerArray.map(({ coin, amount }, j) => {
                                                            const symbolOfCoin = coinData.find(({ symbol }) => symbol === coin);
                                                            const coinColor = symbolOfCoin ? symbolOfCoin.hexColor : '#fff';
                                                            return (
                                                                <div
                                                                    className="case-loot-item spin flex column center"
                                                                    key={j}
                                                                    style={{ borderBottom: `5px solid ${coinColor}` }}
                                                                >
                                                                    <img src={`/images/logos/${coin.toLowerCase()}.png`} alt={`${coin} logo`} />
                                                                    <span><b>${parseFloat(amount.$numberDecimal).toFixed(2)}</b></span>
                                                                    <p>{coin}</p>
                                                                    <div
                                                                        className="gradient-overlay"
                                                                        style={{
                                                                            background: `linear-gradient(to bottom, #232332 0%, ${coinColor} 100%)`,
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className="bottom-lines-part flex row">
                                            {[...Array(23)].map((_, index) => (
                                                <div key={index} className={`line ${index % 4 === 3 ? 'bold' : ''}`}></div>
                                            ))}
                                        </div>
                                        <img className="target-arrow bottom" src="/img/spinner/target-arrow.png" />
                                    </div>
                                )
                            ) : (
                                <>
                                    <div className="catalog-item">
                                        <h3 className="catalog-item__title">{cases.name}</h3>
                                        <img
                                            className="catalog-item-open-box-flicker"
                                            src="/img/main-content/box-flicker.webp"
                                            alt=""
                                        />
                                        <img
                                            className="catalog-item__back"
                                            src={`/img/cases/${slug}.png`}
                                        />
                                        <img
                                            className="catalog-item__thumbnail"
                                            src="/img/cases/box.png"
                                        />
                                        <div className="prices">
                                            <div className="old-price">
                                                {cases.price.$numberDecimal !== cases.priceWithDiscount.$numberDecimal ? (
                                                    <p>{cases.price.$numberDecimal}$</p>
                                                ) : null}</div>
                                            <div className="price">
                                                <p>${cases.priceWithDiscount.$numberDecimal}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <p>{t("Заголовок 4: Открытие кейсов")}</p>
                                    <div className="open-times-buttons flex row">
                                        {timesOptions.map((times) => (
                                            <button
                                                className={times === openingTimes ? 'active' : ''}
                                                key={times}
                                                onClick={() => chooseOpeningTimes(times)}>
                                                {`x${times}`}
                                            </button>
                                        ))}
                                    </div>
                                    <br />
                                    <div className="open-case-buttons flex row">
                                        <button className="first" onClick={() => openCase(true)}>{t("Кнопка 2: Открытие кейсов")}</button>
                                        <button onClick={() => openCase(false)}>{t("Кнопка 3: Открытие кейсов")}</button>
                                    </div>
                                </>
                            )}

                            <h3>{t("Заголовок 5: Открытие кейсов")}</h3>
                            <div className="case-loots flex wrap">
                                {
                                    cases && coins && coinData &&
                                    cases.coins.map(({ coin, amount, index }) => {
                                        const symbolOfCoin = coinData.find(({ symbol }) => symbol === coin);
                                        const coinColor = symbolOfCoin ? symbolOfCoin.hexColor : '#fff'; // default color
                                        return (
                                            <div className="case-loot-item flex column center"
                                                key={index}
                                                style={{ borderBottom: `5px solid ${coinColor}` }}
                                            >
                                                <img src={`/images/logos/${coin.toLowerCase()}.png`} />
                                                <p><b>${parseFloat(amount.$numberDecimal).toFixed(2)}</b></p>
                                                <p>{coin}</p>
                                                <div
                                                    className="gradient-overlay"
                                                    style={{
                                                        background: `linear-gradient(to bottom, #232332 0%, ${coinColor} 100%)`,
                                                    }}
                                                ></div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    ) : <Loading />
                    }
                </>
            )
            }
        </div >
    );
};

export default CaseOpening;
