import { useState, useEffect, useContext, useRef } from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useLanguageContext } from "../context/languageContext";
import { UserContextProvider } from "../context/UserContextProvider";
import Register from './Register';
import toast from "react-hot-toast";
import CloseModalIcon from "../assets/svg/CloseModalIcon";
import useDetectOutsideClick from '../hooks/useDetectOutsideClick';

import axios from '../api/axios';
const LOGIN_URL = '/auth';

const Login = ({ isLoginModalVisible, handleShowLoginModal, onClose, currentLocationPath }) => {
    const { setAuth, persist, setPersist } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    // const from = location.state?.from?.pathname || "/";
    const { t } = useLanguageContext();
    const { updateUserData } = useContext(UserContextProvider);
    const modalRef = useRef(null);

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [isRegisterScreen, setIsRegisterScreen] = useState(false);
    const [isWrongPasswordSent, setIsWrongPasswordSent] = useState(false);

    useDetectOutsideClick(modalRef, () => {
        handleCloseLoginModal();
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            setAuth({ user, pwd, roles, accessToken });
            setUser('');
            setPwd('');
            navigate(currentLocationPath, { replace: true });
            updateUserData();
            handleCloseLoginModal();
        } catch (err) {
            if (!err?.response) {
                toast.error("No server response. Please, contact support.")
            } else if (err.response?.status === 400) {
                toast.error('Missing username or password');
                setIsWrongPasswordSent(true);
            } else if (err.response?.status === 401) {
                toast.error('Wrong username or password');
                setIsWrongPasswordSent(true);
            } else {
                toast.error('Login failed');
                setIsWrongPasswordSent(true);
            }
        }
    }

    // "Remember me" option was simplified and set to persist by default
    // const togglePersist = () => {
    //     setPersist(prev => !prev);
    //     console.log(persist)
    // }

    useEffect(() => {
        setPersist(true);
        localStorage.setItem("persist", persist);
    }, [persist])

    const handleCloseLoginModal = () => {
        handleShowLoginModal(false);
        setIsRegisterScreen(false);
        setIsWrongPasswordSent(false);
        setUser('');
        setPwd('');
    };

    const handleShowRegisterScreen = (boolean) => {
        setIsRegisterScreen(boolean);
    };

    return (
        <>
            {
                isLoginModalVisible ? (
                    <div className="any-modal-background">
                        {isRegisterScreen ? (
                            <Register isRegisterScreen={isRegisterScreen} handleShowRegisterScreen={handleShowRegisterScreen} handleCloseLoginModal={handleCloseLoginModal} />
                        ) : (
                            <div className="any-modal flex column" ref={modalRef}>
                                <div className="flex space-between">
                                    <h1>{t("Заголовок 1: Вход")}</h1>
                                    <div className="close-modal-icon" onClick={handleCloseLoginModal}><CloseModalIcon /></div>
                                </div>
                                <form onSubmit={handleSubmit} className="flex column">
                                    <input
                                        type="text"
                                        id="username"
                                        autoComplete="off"
                                        onChange={(e) => setUser(e.target.value.toLowerCase())}
                                        value={user}
                                        required
                                        placeholder={t("Заголовок 2: Вход")}
                                    />

                                    <input
                                        type="password"
                                        id="password"
                                        onChange={(e) => setPwd(e.target.value)}
                                        value={pwd}
                                        required
                                        autoComplete="current-password"
                                        placeholder={t("Заголовок 3: Вход")}
                                    />
                                    <button>{t("Кнопка 1: Вход")}</button>
                                    {/* <div className="persistCheck flex start">
                                        <input
                                            type="checkbox"
                                            id="persist"
                                            onChange={togglePersist}
                                            checked={persist}
                                        />
                                        <label htmlFor="persist">{t("Текст 1: Вход")}</label>
                                    </div> */}
                                </form>
                                {isWrongPasswordSent ? (
                                    <div className="flex space-between center">
                                        {t("Текст 3: Вход")}
                                        <Link to="https://t.me/clickup_verification_bot" target="_blank"><button className="small">{t("Кнопка 3: Вход")}</button></Link>
                                    </div>
                                ) : (
                                    <div className="flex space-between center">
                                        {t("Текст 2: Вход")}
                                        <button className="small" onClick={() => handleShowRegisterScreen(true)}>{t("Кнопка 2: Вход")}</button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div >
                ) : null}
        </>
    )
}

export default Login
