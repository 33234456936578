import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "../api/axios";
import { useLanguageContext } from "../context/languageContext";
import { CoinContextProvider } from "../context/CoinContextProvider";
import Loading from "./Loading";

const TopUsers = () => {
    const [topLoots, setTopLoots] = useState();
    const [topUsers, setTopUsers] = useState();
    const [sortInterval, setSortInterval] = useState();
    const { t } = useLanguageContext();
    const { coinData } = useContext(CoinContextProvider);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        getTopUsers();
        getTopLoots('all');

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const getTopUsers = async () => {
        try {
            const response = await axios.get('/stats/topusers');
            setTopUsers(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    const getTopLoots = async (timeInterval) => {
        try {
            const response = await axios.get('/stats/toploots', {
                params: { timeInterval }
            });
            setTopLoots(response.data);
            setSortInterval(timeInterval);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div>
            {topLoots && topUsers ? (
                <div className="top-users-block flex center">
                    <div className="top-users-content">
                        <div className="top-users-header">
                            <h1>{t("Заголовок: Топ")}</h1>

                            <nav className="top-users-nav">
                                <div className={`top-users-nav-tab-button ${sortInterval === 'day' ? 'active' : ''}`} onClick={() => getTopLoots('day')}>
                                    <h3>{t("Кнопка 1: Топ")}</h3>
                                </div>
                                <div className={`top-users-nav-tab-button ${sortInterval === 'month' ? 'active' : ''}`} onClick={() => getTopLoots('month')}>
                                    <h3>{t("Кнопка 2: Топ")}</h3>
                                </div>
                                <div className={`top-users-nav-tab-button ${sortInterval === 'all' ? 'active' : ''}`} onClick={() => getTopLoots('all')}>
                                    <h3>{t("Кнопка 3: Топ")}</h3>
                                </div>
                            </nav>
                        </div>

                        <div className="block-currencies">
                            {topLoots && coinData && topLoots.topForRequestedPeriod.map(({ username, amount, coin, _id }, index) => {
                                const symbolOfCoin = coinData.find(({ symbol }) => symbol === coin);
                                const coinColor = symbolOfCoin ? symbolOfCoin.hexColor : '#fff'; // default color

                                return (
                                    <div className="block-currency" key={_id}>
                                        <>
                                            <Link to={`/user/${username}`}>
                                                <div className="block-currency-header">
                                                    <div className="block-currency-header-gallery">
                                                        <div className="block-currency-header-gallery-num">
                                                            <p>{index + 1}</p>
                                                        </div>
                                                        <img src="/img/main-content/user-avatar.svg" alt="" />
                                                    </div>

                                                    <h3>{username}</h3>
                                                </div>
                                            </Link>

                                            <div className="block-currency-content">
                                                <img className="glow" style={{ backgroundColor: coinColor }} alt="" />
                                                <img src={`/images/logos/${coin.toLowerCase()}.png`} alt="" />
                                            </div>

                                            <div className="block-currency-totals">
                                                <div className="block-currency-cripto-sum"><p></p></div>
                                                <div className="block-currency-sum-dollar">
                                                    <div className="rounded-top"></div>
                                                    <div className="rounded-bottom"></div>
                                                    <p>${parseFloat(amount.$numberDecimal).toFixed(2)}</p>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="users-list-table">
                            <table className="users-list">
                                <thead className="users-list-header">
                                    <tr>
                                        <th className="users-list-header__ids-column">{t("Таблица 1: Топ")}</th>
                                        <th className="users-list-header__users-column">{t("Таблица 2: Топ")}</th>
                                        <th className="users-list-header__empty-column"></th>
                                        <th className="users-list-header__cases-column">{t("Таблица 3: Топ")}</th>
                                        <th className="users-list-header__profit-column">{t("Таблица 4: Топ")}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {topUsers.topTwenty.map(({ username, totalCasesOpened, totalWinnings, _id }, index) => (
                                        <tr className="users-list-user" key={_id}>
                                            <td className="users-list-user__ids-column"><p>{index + 1}</p></td>
                                            <td className="users-list-user__users-column">
                                                <img src="/img/main-content/user-avatar.svg" alt="" />
                                                <p>{username}</p>
                                            </td>
                                            <td className="users-list-user__empty-column"></td>
                                            <td className="users-list-user__cases-column"><p>{totalCasesOpened}</p></td>
                                            <td className="users-list-user__profit-column"><p>{parseFloat(totalWinnings.$numberDecimal).toFixed(2)}$</p></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : <Loading />
            }
        </div >
    );
};

export default TopUsers;
