import { Link, NavLink } from "react-router-dom"
import { useLanguageContext } from "../context/languageContext";
import packageJson from '../../package.json';

import GamesIcon from "../assets/svg/GamesIcon";
import TopUsersIcon from "../assets/svg/TopUsersIcon";
import LiveIcon from "../assets/svg/LiveIcon";
import FaqIcon from "../assets/svg/FaqIcon";
import RefIcon from "../assets/svg/RefIcon";
import MyProfileIcon from "../assets/svg/MyProfileIcon";

const Footer = () => {
    const { t } = useLanguageContext();
    const currentYear = new Date().getFullYear();

    return (
        <footer className="site-footer">
            <div className="site-footer__col-1">
                <div className="site-footer-social-links">
                    <div className="site-footer-social-links__logo">
                        <img src="/img/header/Logo.svg" alt="" />
                    </div>
                    <div className="site-footer-social-links__links-list">
                        <a href={t("Ссылка 2: Соцсети (YouTube)")} target="_blank" className="youtube">
                            <img src="/img/footer/youtube-white.svg" alt="" />
                        </a>
                        <a href={t("Ссылка 1: Соцсети (Telegram канал)")} target="_blank" className="telegram">
                            <img src="/img/footer/telegram-white.svg" alt="" />
                        </a>
                    </div>
                </div>

                <div className="site-info">
                    <p className="copyright">{currentYear} {t("Текст 1: Футер")}</p>
                    <p>{t("Текст 2: Футер")}</p>
                    <div className="site-info-nav">
                        <Link to="/agreement">{t("Ссылка 1: Футер")}</Link>
                        <a href={t("Ссылка 3: Соцсети (Telegram саппорт чат)")} target="_blank">{t("Ссылка 2: Футер")}</a>
                        <p>v{packageJson.version}</p>
                    </div>
                </div>
            </div>
            <div className="site-footer__col-2">
                <a href={t("Ссылка 3: Соцсети (Telegram саппорт чат)")} target="_blank"><div className="support-button">
                    <p>{t("Кнопка: Футер")}</p>
                </div>
                </a>

                <div className="site-footer__nav">
                    <NavLink to="/" activeclassname="active">
                        <div><GamesIcon /></div>
                        <span>{t("Ссылка 3: Футер")}</span>
                    </NavLink>
                    <NavLink to="withdrawals" activeclassname="active">
                        <div><LiveIcon /></div>
                        <span>{t("Ссылка 4: Футер")}</span>
                    </NavLink>
                    <NavLink to="referral" activeclassname="active">
                        <div><RefIcon /></div>
                        <span>{t("Ссылка 7: Футер")}</span>
                    </NavLink>
                    <NavLink to="top" activeclassname="active">
                        <div><TopUsersIcon /></div>
                        <span>{t("Ссылка 5: Футер")}</span>
                    </NavLink>
                    <NavLink to="help" activeclassname="active">
                        <div><FaqIcon /></div>
                        <span>{t("Ссылка 6: Футер")}</span>
                    </NavLink>
                    <NavLink to="me" activeclassname="active">
                        <div><MyProfileIcon /></div>
                        <span>{t("Ссылка 8: Футер")}</span>
                    </NavLink>
                </div>
            </div>
        </footer>
    )
}

export default Footer