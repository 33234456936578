import React, { createContext, useState, useEffect } from "react";
import axios from "../api/axios";

export const CoinContextProvider = createContext();

export const CoinProvider = ({ children }) => {
    const [coinData, setCoinData] = useState(null);

    const updateCoinData = async () => {
        try {
            const response = await axios.get('/fetchcoins');
            setCoinData(response.data);
        } catch (err) {
            console.error('Error fetching coin data:', err);
        }
    };

    useEffect(() => {
        updateCoinData();
    }, []);

    return (
        <CoinContextProvider.Provider value={{ coinData, updateCoinData }}>
            {children}
        </CoinContextProvider.Provider>
    );
};
