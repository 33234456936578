import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { UserContextProvider } from "../context/UserContextProvider";
import { CoinContextProvider } from "../context/CoinContextProvider";
import { useLanguageContext } from "../context/languageContext";
import useLogout from "../hooks/useLogout";
import Loading from "./Loading";
import Deposit from "./Deposit";
import ActivateTelegram from "./ActivateTelegram";
import ChangePassword from "./ChangePassword";
import TryKYC from "./TryKYC";

const UserData = () => {

    const logout = useLogout();
    const navigate = useNavigate();
    const { t } = useLanguageContext();
    const { userData, updateUserData } = useContext(UserContextProvider);
    const { coinData } = useContext(CoinContextProvider);

    const [loot, setLoot] = useState();
    const [totalCasesOpenedByUser, setTotalCasesOpenedByUser] = useState(0);
    const [startingTimestamp, setStartingTimestamp] = useState();
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
    const [isTelegramActivationModalVisible, setIsTelegramActivationModalVisible] = useState(false);
    const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);
    const [isTryKYCModalVisible, setIsTryKYCModalVisible] = useState(false);

    const [currentGameTypeStatsMobileScreen, setCurrentGameTypeStatsMobileScreen] = useState('cases');
    const currentGameTypes = ['cases', 'races', 'predictions'];

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        if (userData && userData.username) {
            getUserLoot();
            getTopUserWinAmount();
        }

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [userData])

    useEffect(() => {
        const interval = setInterval(() => {
            const currentIndex = currentGameTypes.indexOf(currentGameTypeStatsMobileScreen);
            const nextIndex = (currentIndex + 1) % currentGameTypes.length;
            setCurrentGameTypeStatsMobileScreen(currentGameTypes[nextIndex]);
        }, 4000);

        return () => clearInterval(interval);
    }, [currentGameTypeStatsMobileScreen, currentGameTypes]);

    const getUserLoot = async () => {
        try {
            const response = await axios.get(`/loots/${userData.username}`, {
                params: {
                    startingTimestamp
                }
            });
            const moreLoot = response.data.loot
            setLoot(loot ? [...loot, ...moreLoot] : moreLoot)
            setStartingTimestamp(response.data.pagination.nextPage)
        } catch (err) {
            console.error(err);
            setIsLastPage(true)
        } finally {
            setIsLoading(false);
        }
    }

    const getTopUserWinAmount = async () => {
        try {
            const response = await axios.get(`/stats/useroverall/`, {
                params: {
                    username: userData.username
                }
            });
            setTotalCasesOpenedByUser(response.data.totalCases[0].totalCasesOpened)
        } catch (err) {
            console.error(err);
        }
    }

    const signOut = async () => {
        navigate("/", { replace: true });
        await logout();
        updateUserData();
    }

    const handleShowDepositModal = (boolean) => {
        setIsDepositModalVisible(boolean);
    };

    const handleShowTelegramActivationModal = (boolean) => {
        setIsTelegramActivationModalVisible(boolean);
    };

    const handleShowChangePasswordModal = (boolean) => {
        setIsChangePasswordModalVisible(boolean);
    };

    const handleShowActivateKYCModal = (boolean) => {
        setIsTryKYCModalVisible(boolean);
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Deposit isDepositModalVisible={isDepositModalVisible} handleShowDepositModal={handleShowDepositModal} />
                    <ActivateTelegram isTelegramActivationModalVisible={isTelegramActivationModalVisible} handleShowTelegramActivationModal={handleShowTelegramActivationModal} />
                    <ChangePassword isChangePasswordModalVisible={isChangePasswordModalVisible} handleShowChangePasswordModal={handleShowChangePasswordModal} />
                    <TryKYC isTryKYCModalVisible={isTryKYCModalVisible} handleShowActivateKYCModal={handleShowActivateKYCModal} />
                    {userData ? (
                        <div className="flex center">
                            <div className="user-overview">
                                <div className="header">
                                    <div className="top-block flex column">
                                        <h1 className="flex end">{t("Заголовок 2: Профиль пользователя")}</h1>
                                        <div className="top-half">
                                        </div>
                                        <div className="bottom-half flex row">
                                            <div className="before-avatar"></div>
                                            <div className="under-avatar flex end">
                                                <div className="avatar-part">
                                                    <img className="avatar" src="/img/main-content/user-avatar.svg" />
                                                </div>
                                                <div className="background-part"></div>
                                            </div>
                                            <div className="after-avatar flex">
                                                <div className="total-games flex row">
                                                    <div className={currentGameTypeStatsMobileScreen === 'cases' ? 'cases' : 'hidden'}>
                                                        <div className="flex row">
                                                            <div className="icon-background">
                                                                <img className="icon-svg" src="/img/user-overview/case-white.svg" />
                                                            </div>
                                                            <div className="flex column">
                                                                <div className="amount">{totalCasesOpenedByUser}</div>
                                                                <div className="type">{t("Текст 1: Профиль пользователя")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={currentGameTypeStatsMobileScreen === 'races' ? 'races' : 'hidden'}>
                                                        <div className="flex row">
                                                            <div className="icon-background">
                                                                <img className="icon-svg" src="/img/user-overview/races-white.svg" />
                                                            </div>
                                                            <div className="flex column">
                                                                <div className="amount">-</div>
                                                                <div className="type">{t("Текст 2: Профиль пользователя")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={currentGameTypeStatsMobileScreen === 'predictions' ? 'predictions' : 'hidden'}>
                                                        <div className="flex row">
                                                            <div className="icon-background">
                                                                <img className="icon-svg" src="/img/user-overview/predictions-white.svg" />
                                                            </div>
                                                            <div className="flex column">
                                                                <div className="amount">-</div>
                                                                <div className="type">{t("Текст 3: Профиль пользователя")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-block flex row space-between">
                                        <div className="flex row baseline">
                                            <div className="nickname">{userData.username}</div>
                                            <img className="logout" src="/img/header/logout.svg" title="Logout" onClick={signOut} />
                                        </div>
                                        <div className="user-balance flex row">
                                            <div className="max-win flex row center">
                                                <div className="title">
                                                    {t("Текст 5: Профиль пользователя")}
                                                </div>
                                                <div className="sum mybalance">
                                                    ${parseFloat(userData.balance.$numberDecimal).toFixed(2)}
                                                </div>
                                            </div>
                                            <div className="balance-buttons flex row">
                                                <Link to="/withdraw"><button className="withdraw">{t("Кнопка 2: Профиль пользователя")}</button></Link>
                                                <button className="deposit" onClick={() => handleShowDepositModal(true)}>{t("Кнопка 3: Профиль пользователя")}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="additional-block flex row">
                                        <div className="referals">
                                            <h4>{t("Заголовок 3: Профиль пользователя")}</h4>
                                            <div className="text-description">{t("Текст 6: Профиль пользователя")}</div>
                                            <div className="buttons flex row space-between">
                                                <div className="total-ref-gains flex center">
                                                    <div className="title">
                                                        {t("Текст 8: Профиль пользователя")}
                                                    </div>
                                                    <div className="sum mybalance">
                                                        ${parseFloat(userData.totalRefRewards.$numberDecimal).toFixed(2)}
                                                    </div>
                                                </div>
                                                <Link to="/referral"><button>{t("Текст 9: Профиль пользователя")}</button></Link>
                                            </div>
                                        </div>
                                        <div className="security">
                                            <h4>{t("Заголовок 4: Профиль пользователя")}</h4>
                                            <div className="text-description">{t("Текст 7: Профиль пользователя")}</div>
                                            <div className="buttons flex row space-between">
                                                {userData.verified ?
                                                    (<button className="telegram-is-activated">{t("Кнопка 6: Профиль пользователя")}</button>
                                                    ) : (
                                                        <button className="telegram" onClick={() => handleShowTelegramActivationModal(true)}>{t("Кнопка 4: Профиль пользователя")}</button>
                                                    )}
                                                <button onClick={() => handleShowChangePasswordModal(true)}>{t("Кнопка 5: Профиль пользователя")}</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="additional-block flex row">
                                        <div className="security">
                                            <h4>{t("Заголовок 8: KYC")}</h4>
                                            <div className="text-description">{t("Текст 9: KYC")}</div>
                                            <div className="buttons flex row space-between">
                                                {userData.kycCompleted ?
                                                    (<button className="kyc-is-activated">{t("Кнопка 7: KYC")}</button>
                                                    ) : (
                                                        userData.kycPending ? (<button className="kyc-is-pending">{t("Кнопка 8: KYC")}</button>) : (
                                                            <button className="kyc" onClick={() => handleShowActivateKYCModal(true)}>{t("Кнопка 9: KYC")}</button>)
                                                    )}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {loot &&
                                    <>
                                        <div className="top-users-block flex center">
                                            <div className="top-users-content">
                                                <div className="top-users-header">
                                                    <h3>{t("Заголовок 1: Профиль пользователя")}</h3>

                                                    <nav className="top-users-nav">
                                                        <div className="top-users-nav-tab-button active">
                                                            <h3>{t("Вкладка 1: Профиль пользователя")}</h3>
                                                        </div>
                                                        <div className="top-users-nav-tab-button disabled">
                                                            <h3>{t("Вкладка 2: Профиль пользователя")}</h3>
                                                        </div>
                                                        <div className="top-users-nav-tab-button disabled">
                                                            <h3>{t("Вкладка 3: Профиль пользователя")}</h3>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="case-loots flex wrap">
                                            {
                                                loot && coinData &&
                                                loot.map(({ coin, amount, caseSlug, _id }) => {
                                                    // const targetCoin = coins.find(({ symbol }) => symbol === coin);
                                                    // const price = parseFloat(amount.$numberDecimal / targetCoin.latestPrice.$numberDecimal).toFixed(4);
                                                    const symbolOfCoin = coinData.find(({ symbol }) => symbol === coin);
                                                    const coinColor = symbolOfCoin ? symbolOfCoin.hexColor : '#fff'; // default color
                                                    return (
                                                        <Link to={`/cases/${caseSlug}`} key={_id}>
                                                            <div className="case-loot-item flex column center"
                                                                style={{ borderBottom: `5px solid ${coinColor}` }}
                                                            >
                                                                <img src={`/images/logos/${coin.toLowerCase()}.png`} />
                                                                <p><b>${parseFloat(amount.$numberDecimal).toFixed(2)}</b></p>
                                                                <p>{coin}</p>
                                                                <div
                                                                    className="gradient-overlay"
                                                                    style={{
                                                                        background: `linear-gradient(to bottom, #232332 0%, ${coinColor} 100%)`,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </Link>
                                                    );
                                                })
                                            }
                                        </div>

                                        {!isLastPage && (
                                            <div className="flex center">
                                                <button className="more" onClick={getUserLoot}>
                                                    {t("Кнопка 1: Профиль пользователя")}
                                                </button>
                                            </div>
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </>
            )}
        </>
    )
}

export default UserData