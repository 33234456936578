import { useState, useEffect } from "react";
import axios from "../api/axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLanguageContext } from "../context/languageContext";
import Loading from "./Loading";

const CasesTable = () => {
    const [cases, setCases] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useLanguageContext();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getCasesTable = async () => {
            try {
                const response = await axios.get('/fetchcases', {
                    signal: controller.signal
                });
                isMounted && setCases(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getCasesTable();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    return (
        <>
            {cases?.length
                ? (
                    <div className="catalog">
                        <h1>{t("Заголовок 1: Открытие кейсов")}</h1>
                        <div className="catalog-list">
                            {cases &&
                                cases
                                    .filter(({ active, availableAmount }) => active && availableAmount > 0)
                                    .sort((a, b) => a.priceWithDiscount.$numberDecimal - b.priceWithDiscount.$numberDecimal)
                                    .map(({ _id, name, slug, price, priceWithDiscount }) => (
                                        <Link to={`/cases/${slug}`} key={_id}>
                                            <div className="catalog-item">
                                                <h3 className="catalog-item__title">{name}</h3>
                                                <img className="catalog-item-open-box-flicker" src="/img/main-content/box-flicker.webp" alt="" />
                                                <img className="catalog-item__back" src={`/img/cases/${slug}.png`} />
                                                <img className="catalog-item__thumbnail" src="/img/cases/box.png" />
                                                <div className="prices">
                                                    <div className="old-price">
                                                        {price.$numberDecimal !== priceWithDiscount.$numberDecimal ? <p>{price.$numberDecimal}$</p> : null}
                                                    </div>
                                                    <div className="price">
                                                        <p>${priceWithDiscount.$numberDecimal}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                        </div>
                    </div>
                ) : <Loading />}
        </>
    );
};

export default CasesTable;
