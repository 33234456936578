// TODO: Move Loot provider only above Cases game pages later (db calls optimization)

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './i18next';
import { LanguageContextProvider } from './context/languageContext';
import { UserProvider } from './context/UserContextProvider';
import { LootProvider } from './context/LootContextProvider';
import { CoinProvider } from './context/CoinContextProvider';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>
          <LootProvider>
            <CoinProvider>
              <LanguageContextProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </LanguageContextProvider>
            </CoinProvider>
          </LootProvider>
        </UserProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);