// TODO: 
// put total stats in react context if you want to increment/update it live
// fix login button behaviour on page refresh (check auth.accessToken?)

import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import axios from "../api/axios";
import useLogout from "../hooks/useLogout";
import useProfile from "../hooks/useProfile";
import { useLanguageContext } from "../context/languageContext";
import LanguageSelect from "./LanguageSelect";
import { UserContextProvider } from "../context/UserContextProvider";
import Login from "./Login";
import useAuth from "../hooks/useAuth";
import Deposit from "./Deposit";
import ActivateTelegram from "./ActivateTelegram";
import TryKYC from "./TryKYC";

import GamesIcon from "../assets/svg/GamesIcon";
import TopUsersIcon from "../assets/svg/TopUsersIcon";
import LiveIcon from "../assets/svg/LiveIcon";
import RefIcon from "../assets/svg/RefIcon";
import FaqIcon from "../assets/svg/FaqIcon";
import MyProfileIcon from "../assets/svg/MyProfileIcon";

const Header = () => {
    const navigate = useNavigate();
    const logout = useLogout();
    const profile = useProfile();
    const { t } = useLanguageContext();
    const { userData, updateUserData } = useContext(UserContextProvider);
    const { auth } = useAuth();

    const [totalStats, setTotalStats] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
    const [isTelegramActivationModalVisible, setIsTelegramActivationModalVisible] = useState(false);
    const [isTryKYCModalVisible, setIsTryKYCModalVisible] = useState(false);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        getTotalStats();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const getTotalStats = async () => {
        try {
            const response = await axios.get('/stats');
            setTotalStats(response.data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    const signOut = async () => {
        navigate("/", { replace: true });
        await logout();
        updateUserData();
    }

    const handleMobileMenuClick = () => {
        setIsMobileMenuVisible(prevState => !prevState);
    }

    const handleShowLoginModal = (boolean) => {
        setIsLoginModalVisible(boolean);
    };

    const handleShowDepositModal = (boolean) => {
        setIsDepositModalVisible(boolean);
    };

    const handleShowTelegramActivationModal = (boolean) => {
        setIsTelegramActivationModalVisible(boolean);
    };

    const handleShowActivateKYCModal = (boolean) => {
        setIsTryKYCModalVisible(boolean);
    };

    return (
        <>
            <Login isLoginModalVisible={isLoginModalVisible} handleShowLoginModal={handleShowLoginModal} />
            <Deposit isDepositModalVisible={isDepositModalVisible} handleShowDepositModal={handleShowDepositModal} />
            <ActivateTelegram isTelegramActivationModalVisible={isTelegramActivationModalVisible} handleShowTelegramActivationModal={handleShowTelegramActivationModal} />
            <TryKYC isTryKYCModalVisible={isTryKYCModalVisible} handleShowActivateKYCModal={handleShowActivateKYCModal} />
            <div>
                {userData && userData.verified && !userData.kycCompleted && !userData.kycPending ? (
                    <div className="header-alert-verify-kyc flex center">
                        <div className="text">{t("Текст 5: KYC")}</div>
                        <button className="kyc" onClick={() => handleShowActivateKYCModal(true)}> {t("Кнопка 4: KYC")}</button>
                    </div>
                ) : null}
            </div>
            <div>
                {userData && userData.verified === false ? (
                    <div className="header-alert-verify-telegram flex center">
                        <div className="text">{t("Текст 4: Активация Telegram")}</div>
                        <button className="telegram" onClick={() => handleShowTelegramActivationModal(true)}>{t("Кнопка 1: Активация Telegram")}</button>
                    </div>
                ) : null}
            </div>
            <div className="site-header">
                <Link to="/">
                    <img src="/img/header/Logo.svg" alt="" className="site-logo" />
                </Link>

                <div className="site-header__actions">
                    <ul className="social-media">
                        <li className="social-media-item first-row-list-item">
                            <a href={t("Ссылка 2: Соцсети (YouTube)")} target="_blank">
                                <img src="/img/social-media/youtube.svg" alt="" />
                            </a>
                        </li>
                        <li className="social-media-item first-row-list-item">
                            <a href={t("Ссылка 1: Соцсети (Telegram канал)")} target="_blank">
                                <img src="/img/social-media/telegram.svg" alt="" />
                            </a>
                        </li>
                    </ul>

                    <LanguageSelect parentClassName={"languages-button"} />

                    <ul className="site-nav">
                        <li className="site-nav__item">
                            <NavLink to="/" activeclassname="active">
                                <div><GamesIcon /></div>
                                <span>{t("Ссылка 6: Хедер")}</span>
                            </NavLink>
                        </li>
                        {/* <li className="site-nav__item">
                            <NavLink to="#" activeclassname="active">
                                <div><ContractsIcon /></div>
                                <span>Контракты</span>
                            </NavLink>
                        </li> */}
                        <li className="site-nav__item">
                            <NavLink to="top" activeclassname="active">
                                <div><TopUsersIcon /></div>
                                <span>{t("Ссылка 2: Хедер")}</span>
                            </NavLink>
                        </li>
                        <li className="site-nav__item">
                            <NavLink to="withdrawals" activeclassname="active">
                                <div><LiveIcon /></div>
                                <span>{t("Ссылка 3: Хедер")}</span>
                            </NavLink>
                        </li>
                        <li className="site-nav__item">
                            <NavLink to="referral" activeclassname="active">
                                <div><RefIcon /></div>
                                <span>{t("Ссылка 5: Хедер")}</span>
                            </NavLink>
                        </li>
                        <li className="site-nav__item">
                            <NavLink to="help" activeclassname="active">
                                <div><FaqIcon /></div>
                                <span>{t("Ссылка 4: Хедер")}</span>
                            </NavLink>
                        </li>
                    </ul>

                    {auth.accessToken ? (
                        userData &&
                        <div className="header-profile flex row">
                            <Link to="me">
                                <img className="header-profile avatar" src="/img/main-content/user-avatar.svg" />
                            </Link>
                            <div className="flex column">
                                <div className="header-profile nickname-and-logout flex row space-between">
                                    <Link to="me">
                                        <div className="header-profile nickname">{userData.username}</div>
                                    </Link>
                                    <img className="header-profile logout" src="/img/header/logout.svg" title="Logout" onClick={signOut} />
                                </div>
                                <div className="header-profile balance flex row" onClick={() => handleShowDepositModal(true)}>
                                    <div className="deposit flex center">
                                        <img src="/img/header/plus.svg" />
                                    </div>
                                    ${parseFloat(userData.balance.$numberDecimal).toFixed(2)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="login-button" onClick={() => handleShowLoginModal(true)}>
                            <p>{t("Кнопка 3: Хедер")}</p>
                            <img src="/img/header/login.svg" alt="" />
                        </div>
                    )}

                    <div className="header-hamburger" onClick={handleMobileMenuClick}>
                        <img src="/img/header/hamburger.svg" alt="" />
                    </div>
                </div>
            </div >

            <div className="mobile-menu" style={{ display: isMobileMenuVisible ? 'grid' : 'none' }}>
                <ul className="mobile-menu-site-nav">
                    <li className="site-nav__item">
                        <NavLink to="/" activeclassname="active" onClick={handleMobileMenuClick}>
                            <div><GamesIcon /></div>
                            <span>{t("Ссылка 6: Хедер")}</span>
                        </NavLink>
                    </li>
                    {/* <li className="site-nav__item">
                            <NavLink to="#" activeclassname="active">
                                <div><ContractsIcon /></div>
                                <span>Контракты</span>
                            </NavLink>
                        </li> */}
                    <li className="site-nav__item">
                        <NavLink to="top" activeclassname="active" onClick={handleMobileMenuClick}>
                            <div><TopUsersIcon /></div>
                            <span>{t("Ссылка 2: Хедер")}</span>
                        </NavLink>
                    </li>
                    <li className="site-nav__item">
                        <NavLink to="withdrawals" activeclassname="active" onClick={handleMobileMenuClick}>
                            <div><LiveIcon /></div>
                            <span>{t("Ссылка 3: Хедер")}</span>
                        </NavLink>
                    </li>
                    <li className="site-nav__item">
                        <NavLink to="referral" activeclassname="active" onClick={handleMobileMenuClick}>
                            <div><RefIcon /></div>
                            <span>{t("Ссылка 5: Хедер")}</span>
                        </NavLink>
                    </li>
                    <li className="site-nav__item">
                        <NavLink to="help" activeclassname="active" onClick={handleMobileMenuClick}>
                            <div><FaqIcon /></div>
                            <span>{t("Ссылка 4: Хедер")}</span>
                        </NavLink>
                    </li>
                    <li className="site-nav__item">
                        <NavLink to="me" activeclassname="active" onClick={handleMobileMenuClick}>
                            <div><MyProfileIcon /></div>
                            <span>{t("Ссылка 7: Хедер")}</span>
                        </NavLink>
                    </li>
                </ul>

                <div className="mobile-menu-footer">
                    <li>
                        <LanguageSelect parentClassName={"mobile-menu-languages-button"} />
                    </li>

                    <li>
                        <ul className="mobile-menu-social-media">
                            <li className="social-media-item first-row-list-item">
                                <a href={t("Ссылка 2: Соцсети (YouTube)")} target="_blank">
                                    <img src="/img/social-media/youtube.svg" alt="" />
                                </a>
                            </li>
                            <li className="social-media-item first-row-list-item">
                                <a href={t("Ссылка 1: Соцсети (Telegram канал)")} target="_blank">
                                    <img src="/img/social-media/telegram.svg" alt="" />
                                </a>
                            </li>
                        </ul>
                    </li>
                </div>
            </div>

            <div className="first-row">
                <ul className="social-media">
                    <li className="social-media-item first-row-list-item">
                        <a href={t("Ссылка 2: Соцсети (YouTube)")} target="_blank">
                            <img src="/img/social-media/youtube.svg" alt="" />
                            <span>Youtube</span>
                        </a>
                    </li>
                    <li className="social-media-item first-row-list-item">
                        <a href={t("Ссылка 1: Соцсети (Telegram канал)")} target="_blank">
                            <img src="/img/social-media/telegram.svg" alt="" />
                            <span>Telegram</span>
                        </a>
                    </li>
                </ul>
                <ul className="user-data">
                    <li className="first-row-list-item">
                        <Link to="/top">
                            <img src="/img/social-media/users.svg" alt="" />
                            <div>
                                <h5>{isLoading ? "-" : totalStats && totalStats.totalUsers}</h5>
                                <p>{t("Текст 1: Хедер")}</p>
                            </div>
                        </Link>
                    </li>
                    <li className="first-row-list-item">
                        <Link to="/cases">
                            <img src="/img/social-media/case.svg" alt="" />
                            <div>
                                <h5>{isLoading ? "-" : totalStats && totalStats.totalCases}</h5>
                                <p>{t("Текст 2: Хедер")}</p>
                            </div>
                        </Link>
                    </li>
                    <li className="first-row-list-item">
                        <Link to="#">
                            <img src="/img/social-media/races.svg" alt="" />
                            <div>
                                <h5>-</h5>
                                <p>{t("Текст 4: Хедер")}</p>
                            </div>
                        </Link>
                    </li>
                    <li className="first-row-list-item">
                        <Link to="#">
                            <img src="/img/social-media/predictions.svg" alt="" />
                            <div>
                                <h5>-</h5>
                                <p>{t("Текст 3: Хедер")}</p>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Header