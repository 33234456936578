import { Link } from "react-router-dom"

const Missing = () => {
    return (
        <div className="forbidden-page flex column center">
            <h1>Хммм...</h1>
            <p>Страница не найдена</p>
            <Link to="/"> <button>Вернуться на главную</button></Link>
        </div >
    )
}

export default Missing
