import { useRef, useState, useEffect } from "react";
import { axiosPrivate } from "../api/axios";
import { useLanguageContext } from "../context/languageContext";
import toast from "react-hot-toast";
import CloseModalIcon from "../assets/svg/CloseModalIcon";
import useDetectOutsideClick from "../hooks/useDetectOutsideClick";

const PWD_REGEX = /^.{8,24}$/;

const ChangePassword = ({ isChangePasswordModalVisible, handleShowChangePasswordModal }) => {
    const modalRef = useRef(null);
    const { t } = useLanguageContext();

    const [oldPwd, setOldPwd] = useState('');

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useDetectOutsideClick(modalRef, () => {
        handleCloseChangePasswordModal();
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = PWD_REGEX.test(oldPwd);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            toast.error("Invalid Entry");
            return;
        }
        try {
            const response = await axiosPrivate.put('/me/changepassword',
                JSON.stringify({ oldPwd, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setOldPwd('');
            setPwd('');
            setMatchPwd('');
            toast.success("New password set successfully");
            handleCloseChangePasswordModal();
        } catch (err) {
            if (!err?.response) {
                toast.error('No Server Response');
            } else {
                toast.error(`${JSON.stringify(err.response?.data.message).replace(/"/g, '')}`);
            }
        }
    }

    const handleCloseChangePasswordModal = () => {
        handleShowChangePasswordModal(false);
        setOldPwd('');
        setPwd('');
        setMatchPwd('');
    };

    return (
        <>
            {isChangePasswordModalVisible ? (
                <div className="any-modal-background">
                    <div className="any-modal flex column" ref={modalRef}>
                        <div className="flex space-between">
                            <h1>{t("Заголовок 1: Смена пароля")}</h1>
                            <div className="close-modal-icon" onClick={handleCloseChangePasswordModal}><CloseModalIcon /></div>
                        </div>

                        <form className="change-password" onSubmit={handleSubmit}>
                            <p>{t("Текст 1: Смена пароля")}</p>
                            <input
                                type="password"
                                id="password-current"
                                onChange={(e) => setOldPwd(e.target.value)}
                                value={oldPwd}
                                required
                                placeholder="*********"
                                autoComplete="current-password"
                            />

                            <p>{t("Текст 2: Смена пароля")}</p>
                            <input
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                                placeholder="*********"
                                autoComplete="new-password"
                            />
                            <label htmlFor="password">
                                {validPwd || !pwd ? null : <div className="input-assist">{t("Текст 6: Вход")}</div>}
                            </label>

                            <p>{t("Текст 3: Смена пароля")}</p>
                            <input
                                type="password"
                                id="confirm_pwd"
                                onChange={(e) => setMatchPwd(e.target.value)}
                                value={matchPwd}
                                required
                                placeholder="*********"
                                autoComplete="new-password"
                            />
                            <label htmlFor="confirm_pwd">
                                {validMatch || !matchPwd ? null : <div className="input-assist">{t("Текст 7: Вход")}
                                </div>}
                            </label>
                            <br />
                            <button disabled={!validMatch ? true : false}>{t("Кнопка 1: Смена пароля")}</button>
                        </form>
                    </div>
                </div>
            ) : (
                null
            )
            }
        </>
    )
}

export default ChangePassword
