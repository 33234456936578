import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLanguageContext } from "../context/languageContext";
import { UserContextProvider } from "../context/UserContextProvider";
import toast from "react-hot-toast";
import AboutReferral from "./AboutReferral";
import Loading from "./Loading";

const Referral = () => {
    const axiosPrivate = useAxiosPrivate();
    const { t } = useLanguageContext();
    const { userData } = useContext(UserContextProvider);

    const [myRefCodes, setMyRefCodes] = useState();
    const [myInvitedRefs, setMyInvitedRefs] = useState();
    const [newRefCode, setNewRefCode] = useState('');

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        getMyRefCodes();
        getUsersRefferedByMe();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const getMyRefCodes = async () => {
        try {
            const response = await axiosPrivate.get('/me');
            setMyRefCodes(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    const getUsersRefferedByMe = async () => {
        try {
            const response = await axiosPrivate.get('/me/referredbyme');
            setMyInvitedRefs(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    const toggleAddRefCode = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        try {
            const response = await axiosPrivate.put('/me/addrefcode',
                JSON.stringify({ newRefCode }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            getMyRefCodes();
            setNewRefCode('');
        } catch (err) {
            if (!err?.response) {
                toast.error('No server response. Please, contact the admin.');
            } else if (err.response?.status === 400) {
                toast.error(`${JSON.stringify(err.response?.data.message).replace(/"/g, '')}`);
            } else {
                toast.error('Error occured while creating ref link')
            }
        }
    }

    const handleBufferCopied = (fullLink) => {
        navigator.clipboard.writeText(fullLink);
        toast.success(t("Тост 1: Система"))
    }

    return (
        <>
            <AboutReferral />
            {userData ? (
                <>
                    {
                        myRefCodes && myInvitedRefs ? (
                            <>
                                <div className="myref-block flex column center">
                                    <h3>{t("Заголовок 2: Ваши реферальные ссылки")}</h3>
                                    <div className="total-ref-rewards">
                                        <div className="number">${parseFloat(userData.totalRefRewards.$numberDecimal).toFixed(2)}</div>
                                    </div>
                                    <h3 className="flex center">{t("Заголовок 5: Ваши реферальные ссылки")}</h3>
                                    <div className="invited-users">
                                        {Object.values(myInvitedRefs).flat().length !== 0 ?
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>{t("Таблица 1: Ваши реферальные ссылки")}</th>
                                                        <th>{t("Таблица 2: Ваши реферальные ссылки")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.keys(myInvitedRefs).map(key => (
                                                        <tr key={key}>
                                                            <td>{parseInt(key) + 1}</td>
                                                            <td>{myInvitedRefs[key].join(', ')}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            :
                                            <div className="text-no-refs">
                                                <p>{t("Текст 3: Ваши реферальные ссылки")}</p>
                                                <br />
                                                <p>{t("Текст 4: Ваши реферальные ссылки")}</p>
                                            </div>
                                        }
                                    </div>
                                    <h3>{t("Заголовок 3: Ваши реферальные ссылки")}</h3>
                                    <div className="invite-links-block">
                                        {myRefCodes.myreflinks.map((link, i) => {
                                            const fullLink = `clickup.bet/invite/${link}`;
                                            return (
                                                <div className="invite-link flex space-between" key={i} onClick={() => handleBufferCopied(fullLink)}>
                                                    <div className="link">{fullLink}</div>
                                                    <img
                                                        className="copy-icon"
                                                        src="/img/referral/copy-icon.svg"
                                                        alt="Copy"
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <h3>{t("Заголовок 4: Ваши реферальные ссылки")}</h3>
                                    <form onSubmit={toggleAddRefCode} className="flex column">
                                        <input
                                            type="text"
                                            id="refcode"
                                            autoComplete="off"
                                            onChange={(e) => setNewRefCode(e.target.value)}
                                            value={newRefCode}
                                            required
                                            placeholder={t("Форма: Ваши реферальные ссылки")}
                                        />
                                        <button disabled={!newRefCode}>{t("Кнопка: Ваши реферальные ссылки")}</button>
                                    </form>
                                </div>
                            </>
                        ) : (
                            <Loading />
                        )
                    }
                </>
            ) : (
                null
            )}
        </>
    )
}

export default Referral