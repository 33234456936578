import { Link } from "react-router-dom"

const Unauthorized = () => {

    return (
        <div className="forbidden-page flex column center">
            <h1>Хммм...</h1>
            <p>У вас нет доступа к этой странице</p>
            <Link to="/"> <button>Вернуться на главную</button></Link>
        </div >
    )
}

export default Unauthorized
