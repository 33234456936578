import useAxiosPrivate from './useAxiosPrivate';

const useProfile = () => {
    const axiosPrivate = useAxiosPrivate();

    const getProfile = async () => {
        try {
            const response = await axiosPrivate.get('/me', {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching profile:", error);
            return null;
        }
    };

    return getProfile;
};

export default useProfile;
