import { useLanguageContext } from "../context/languageContext";

const Agreement = () => {
    const { t } = useLanguageContext();

    return (
        <div className="text-page flex center column">
            <h1>Соглашение</h1>
            <div className="text-block warning">
                <p><b>Warning!</b> Please read carefully this User Agreement before using https://clickup.bet and its software.</p>
                <p>Registration (authorization) means that you are agree with all terms of the present agreement.</p>
                <p>If you disagree with terms of the present agreement, do not register (authorize) on the site and don’t use its software.</p>
                <p>Administrator on the one side and the person who accepted the offer placed on the Internet site https://clickup.bet/agreement, on the other side, entered into this user agreement as follows.</p>
            </div>
            <div className="text-block">
                <p className="flex end"><b>From 09 January 2024</b></p>

                <h3>Terms and Definitions</h3>
                <p>1. In this user agreement, unless the text directly implies otherwise, the following terms will have the following meanings:</p>
                <p>«Owner» — Administrator.</p>
                <p>«Inventory» — Possibility to use one of the items specified in the Case.</p>
                <p>«Personal account» — A set of secure pages of the Site created as a result of the User's registration, using which the User has the opportunity to use all or some functionality of the Site.</p>
                <p>«User» — An individual with a account who has entered into an Agreement with the Owner.</p>
                <p>«Site» — A set of information, texts, graphic elements, design, images, photos and video materials, computer programs, other results of intellectual activity (except for the Inventory), contained in the information system that ensures the availability of such information on the Internet within the domain zone https://clickup.bet</p>
                <p>«Agreement» — The present User Agreement.</p>
                <p>«Parties» — Owner and User.</p>
                <p>2. All other terms and definitions found in the text of the Agreement are interpreted by the Parties in accordance with the laws of the Estonia and the rules of interpretation of the relevant terms used in the Internet.</p>
                <p>3. Headings (article titles) of the Agreement are intended solely for the convenience of the text of the Agreement and have no literal legal meaning.</p>

                <h3>Conclusion of the Agreement</h3>
                <p>1. The text of the Agreement, permanently posted at https://clickup.bet/agreement and available at registration (authorization) on the Site, contains all the essential terms of the Agreement and is an offer of the Owner to enter into the Agreement with any fully capable third party using the Site, on the conditions specified in the text of the Agreement. Text of the Agreement is a public offer according to the law of Estonia.</p>
                <p>2. The proper acceptance of this offer in accordance with the law of Estonia is the consistent implementation by the third party of the following actions:</p>
                <p>2.1. Acquaintance with the terms of the Agreement;</p>
                <p>2.2. Putting a symbol in a special field under the heading "I have read and agree to the Terms of Service";</p>
                <p>2.3. Authorization on the Site by clicking on the "Sign In" and authorization through the existing account or by creating a new account.</p>

                <h3>Subject of the Agreement</h3>
                <p>1. Owner grants to User:</p>
                <p>1.1. A royalty-free simple (non-exclusive) license to use the Site and its software for their intended purpose, as provided by the explicit user functions of the Site and the Personal Account;</p>
                <p>1.2. A compensated simple (non-exclusive) license to use the Case for its intended purpose, while the cost of the license for the use of a certain Case is indicated on the Site.</p>
                <p>2. The license specified in clause 3.1.1 of the Agreement is granted to the User for a period during which (and within the territory on which) the Site and the Personal Account remain accessible to the User.</p>
                <p>3. The license specified in clause 3.1.2 of the Agreement is granted to the User within the territory on which the Case remains accessible to the User within the period from the moment when the User pays for the use of a particular Case until the Inventory is determined with the help of this Case.</p>
                <p>4. It is prohibited for User to:</p>
                <p>4.1. Get around the technical restrictions set on the Site and in Case;</p>
                <p>4.2. Study the technology, decompile or disassemble the Site, the Case and the Personal Account, except for cases expressly provided by the legislation of the Estonia;</p>
                <p>4.3. Create copies of Site, Cases or Personal Account and also copies of design;</p>
                <p>4.4. Change Site, Cases in any way;</p>
                <p>4.5. Perform any actions aimed at changing Site, Case, and Personal Account functionality and performance;</p>
                <p>4.6. Grant access of own Personal Account to a third party;</p>
                <p>4.7. Perform any all above mentioned actions as regards to any part of the Site, Case or Personal Account;</p>
                <p>4.8. Create fake Personal Accounts for profit using an Affiliate System.</p>
                <h3>Site, Case and Personal Account functions</h3>
                <p>1. User of the Site has the possibility to:</p>
                <p>1.1. get acquainted with the content and characteristics of the Inventory (the choice of which occurs through choosing a certain Case), and the cost of a license to use such a Case;</p>
                <p>1.2. purchase a license for the use of the Case and receive the relevant Inventory in the manner specified in the Agreement.</p>
                <p>2. User through Case is able to obtain one of the Inventories provided on the page containing the Case. The inventory for receiving by User is determined automatically by using the Case.</p>
                <p>3. User through the Personal Account has the possibility to accept Inventory to the cryptocurrency wallet.</p>

                <h3>Inventory Acceptance</h3>
                <p>1. Once the Inventory is received through the use of the Case and is shown in the Personal Account, the User has the possibility to take Inventory to the cryptocurrency wallet within a day, or to alienate the Inventory through the Personal Account.</p>
                <p>2. Acceptance of the Inventory in the cryptocurrency wallet is subject to the User's fulfilment of the Personal Account settings specified on the Site and in the Personal Account section.</p>
                <p>3. In order to accept the Inventory in the cryptocurrency, subject to the requirements of clause 5.2 of the Agreement, the User follows the button "Withdraw", located in the Personal Account section next to the Inventory description.</p>
                <p>4. User has the possibility to sell the Inventory which has been received but not yet accepted in the cryptocurrency wallet, for the amount of bonus points that is given in the description of the Inventory in the Personal Account; these bonus points give a discount when paying a license fee for Case, with one bonus point equals to one U.S. Dollar exclusively for these purposes (payment of license fees). The parties agreed that these bonus points are not cash and are not refundable and/or exchangeable.</p>
                <p>5. To sell the Inventory, the User within a month from the moment of receiving the Inventory follows the corresponding link located in the Personal Account section next to a description of this Inventory. Inactivity of the User during a month means sale of his/her Inventory, which has been received by User, but not claimed and moved to cryptocurrency wallet.</p>
                <p>6. When the Inventory is alienated, the bonus points received by the User are displayed in the Personal Account.</p>

                <h3>Owner Reward</h3>
                <p>1. For the right to use the Case, the User pays a license fee in the amount indicated on the Site and on the page of the corresponding Case.</p>
                <p>2. The license fee specified in clause 6.1 of the Agreement shall be paid by the User from funds previously transferred to the Owner through a payment service, information about which is available to the User at the moment of payment. The amount of funds is displayed in the Personal Account.</p>
                <p>3. The payment for the license fee specified in clause 6.1 of the Agreement shall be made by the User in the manner and in accordance with the rules specified on the relevant page of the Site, taking into account the specifics and requirements established by the relevant payment service involved by the Owner for payment transactions.</p>
                <p>4. The moment of payment of the license fee specified in clause 6.1 of the Agreement is the moment of writing off the corresponding amount of funds; the User is being informed of this by the corresponding change in the balance in the Personal Account.</p>
                <p>5. The payment of the license fee specified in clause 6.1 of the Agreement in the manner specified in this section is subject to the statements of Clause 5.6 of the Agreement.</p>

                <h3>Personal Data</h3>
                <p>1. The User gives their consent to the Owner for the processing of information, including the User's personal data provided when using the Site.</p>
                <p>2. Processing of personal data means recording, systematization, accumulation, storage, adjustment (updating, modification), extraction, use, transfer (distribution, provision, access), including cross-border, depersonalization, blocking, deletion, destruction of personal data not subject to special categories for which processing, in accordance with the current legislation of Estonia, requires the written consent of the User.</p>
                <p>3. The processing of personal data is carried out in order to fulfil the obligations of the Agreement by the Parties, the registration of the User on the Site, the acquisition of a license for the use of the Case, the receipt of the Inventory, the sending to the User Telegram username of information messages.</p>
                <p>4. The User may at any time withdraw consent to the processing of personal data by sending the Owner a corresponding written notice to the username specified in clause 1.1 of the Agreement by registered Telegram with a receipt acknowledgement. In doing so, the User understands that such withdrawal means termination of the Agreement. The Owner has the right to continue processing the personal data of the User in cases provided by law.</p>
                <p>5. Additional or other provisions regarding the processing of personal data may be contained in the relevant document posted on the Site. In the event of a conflict between the provisions of such a document, the provisions of this section apply the provisions of the document.</p>
                <p>6. The User agrees to receive advertising materials from the Owner, from their affiliates or from other persons on behalf of the Owner at the Telegram username specified by the User upon registration. Consent to receipt of advertising materials may be withdrawn by the User at any time by sending to the Owner the relevant written notification to the username specified in clause 1.1 of the Agreement, or by performing the actions specified in the Telegram messages containing such materials.</p>

                <h3>Limitation of liability</h3>
                <p>1. The Owner is not liable for the User's losses caused by unlawful actions of third parties, including those related to unlawful access to the Personal Account. The Owner is not liable for losses incurred to the User because of disclosure of the credentials necessary for access to the Personal Account to third parties, which occurred through no fault of the Owner.</p>
                <p>2. The Owner is not the owner of the Inventory items, does not determine the Inventory order of use and performance.</p>
                <p>3. The Owner does not provide software for the Inventory intended purpose on the User's device. The User purchases and/or installs this software on their device themselves.</p>
                <p>4. The Site and its software, including the Personal Account and the Cases, are provided "as is". The User undertakes all the risks related with using the Site. The Owner, operators of wired and wireless communication, through the networks of which the access to the Site is provided, affiliated persons, suppliers, agents of the Owner do not provide any guarantees regarding the Site.</p>
                <p>5. The Owner does not guarantee that the Site, Cases and the Personal Account meet the User's requirements, and that the access to the Site, Cases and the Personal Account will be provided continuously, quickly, securely and without errors.</p>
                <p>6. Software and hardware errors, both on the Owner's and the User's side, leading to the inability of the User to access the Site and/or Cases and/or the Personal Account are circumstances of force majeure, and are the basis for exemption from liability for failure to fulfil the Owner's obligations under the Agreement.</p>
                <p>7. The Owner has the right to assign rights and transfer debts for all obligations arising from the Agreement. The User hereby gives their consent to the assignment of rights and the transfer of debts to any third parties. The Owner informs the User on the assignment of rights and/or transfer of debts by placing the relevant information on the Site.</p>
                <p>8. The amount of losses that can be compensated by the Owner to the User is in any case limited in accordance with the provisions of the Estonian law.</p>
                <p>9. In case of violation of the Agreement by the User, the Owner is entitled to unilaterally refuse to execute the Agreement and terminate the User's access to the Personal Account unless otherwise is provided by the Agreement. If such infringement caused damage to third parties, the responsibility for it rests entirely on the User.</p>

                <h3>Settlement of disputes</h3>
                <p>All disputes, disagreements and claims that may arise in connection with the execution, termination or invalidation of the Agreement, the Parties will seek to resolve through negotiations. The Party that has claims and/or disagreements shall forward them in a message to the other Party indicating the claims and/or disagreements in accordance with clause 9.1 of the Agreement.</p>
                <p>If the sending Party does not receive the reply to the message within 30 (thirty) working days from the date of sending the message, or if the Parties do not come to an agreement on the claims and/or disagreements that have arisen, the dispute is subject to judicial settlement at the Owner's location.</p>

                <h3>Refund</h3>
                <p>The user has the right to request a refund, provided that the funds have not been used up after refilling the balance (if it is technically possible to return a payment transaction to a payment method provider.).</p>
                <p>All purchases and activities made through the service on the site are final and non-refundable.</p>

                <h3>Final conditions</h3>
                <p>1. The Parties hereby confirm that in the performance (modification, amendment, termination) of the Agreement, as well as in the conduct of correspondence on these matters, the use of analogues of the handwritten signature of the Parties is permitted. The Parties confirm that all notifications, messages, agreements and documents within the framework of the Parties' performance of the obligations arising from the Agreement, signed by the analogues of the Parties' own handwritten signatures, shall be valid and binding upon the Parties. The analogues of a handwritten signature are understood as authorized Telegram usernames and credentials of the Personal Account.</p>
                <p>2. The Parties acknowledge that all notices, messages, agreements, documents and letters sent using authorized Telegram usersnames and the Personal Account shall be deemed to be sent and signed by the Parties, unless explicitly indicated in the letters.</p>
                <p>3. The authorized Telegram usersnames of the Parties are recognized in the "Contacts" page of https://clickup.bet website (for owner) and Telegram username specified and verified when registering the account (for user).</p>
                <p>4. The Parties undertake to ensure the confidentiality of information and information required to access authorized Telegram usernames and the Personal Account, to prevent the disclosure of such information and the transfer to third parties. The Parties themselves determine the procedure for restricting access to such information.</p>
                <p>5. When authorized Telegram usernames are used, prior to the receipt of information on breach of confidentiality from the second Party, all actions taken and documents sent using the authorized Telegram username of the second Party, even if these actions and documents were committed and directed by other persons, are considered to as if taken and sent by that second Party. In this case, the second Party undertakes all the rights and obligations, as well as liability.</p>
                <p>6. When using the Personal Account prior to receipt of the user information about the violation of the confidentiality, all actions taken and documents sent and sent using the Personal Account even if these actions and the documents were taken and sent by other persons are considered as if taken and sent by the User. In this case, the User undertakes all the rights and obligations, as well as liability.</p>

                <h3>Amendment of the Agreement</h3>
                <p>1. The Owner has the right to unilaterally change the terms of the Agreement, and these changes come into force at the time of publication of the new version of the Agreement on the Internet at https://clickup.bet/agreement.</p>
                <p>2. Continued use of the functions of the Site will mean the User's consent to the terms of the new version of the Agreement. If the User does not agree with the terms of the new version of the Agreement, they must cease to use the Site.</p>
                <p>3. In all other respects, what is not regulated by the Agreement, shall be governed by applicable Estonian law without regard to its conflicts of law provisions.</p>
            </div>
        </div>
    )
}

export default Agreement