import React from 'react';

const LiveIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="LiveIcon" fillRule="evenodd" clipRule="evenodd" d="M12.7266 0H5.27344C2.3625 0 0 2.3625 0 5.27344V12.7266C0 15.6375 2.3625 18 5.27344 18H12.7266C15.6375 18 18 15.6375 18 12.7266V5.27344C18 2.3625 15.6375 0 12.7266 0ZM6.75 11.102V10.125H11.25V11.102C11.25 11.572 11.8181 11.8072 12.1504 11.475L14.2523 9.37301C14.4584 9.16734 14.4584 8.83336 14.2523 8.62734L12.1504 6.52535C11.8181 6.19312 11.25 6.42867 11.25 6.89836V7.87535H6.75V6.89836C6.75 6.42832 6.18187 6.19312 5.84965 6.52535L3.74766 8.62734C3.54164 8.83301 3.54164 9.16699 3.74766 9.37301L5.84965 11.475C6.18187 11.8072 6.75 11.5717 6.75 11.102Z" fill="#7A7A99" />
        </svg>
    );
};

export default LiveIcon;