import React, { createContext, useState, useEffect } from "react";
import axios from "../api/axios";

export const LootContextProvider = createContext();

export const LootProvider = ({ children }) => {
    const [lootData, setLootData] = useState(null);

    const updateLootData = async () => {
        try {
            const response = await axios.get('/loots');
            setLootData(response.data);
        } catch (err) {
            console.error('Error fetching loot data:', err);
        }
    };

    useEffect(() => {
        updateLootData();
    }, []);

    return (
        <LootContextProvider.Provider value={{ lootData, updateLootData }}>
            {children}
        </LootContextProvider.Provider>
    );
};
