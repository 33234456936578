import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import Russian from "./Translation/Russian.json";
import English from "./Translation/English.json";

const resources = {
  ru: {
    translation: Russian,
  },
  en: {
    translation: English,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "ru", //default language
});

export default i18next;
