import React, { createContext, useState, useEffect } from "react";
import { axiosPrivate } from "../api/axios";

export const UserContextProvider = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);

    const updateUserData = async () => {
        try {
            const response = await axiosPrivate.get('/me', {
                withCredentials: true
            });
            setUserData(response.data);
        } catch (err) {
            console.error('Error fetching user data:', err);
            setUserData(undefined);
        }
    };

    useEffect(() => {
        updateUserData();
    }, []);

    return (
        <UserContextProvider.Provider value={{ userData, updateUserData }}>
            {children}
        </UserContextProvider.Provider>
    );
};
