import React from 'react';

const CloseModalIcon = () => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector 1940" d="M18 1.57202L1.14404 18.428M1.14404 1.57202L18 18.428" stroke="#7D7D9D" strokeWidth="2" />
        </svg>
    )
}

export default CloseModalIcon;