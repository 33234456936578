import React from 'react';

const TopUsersIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="TopUsersIcon" d="M4.5 15.6304H3.68182C3.456 15.6304 3.27273 15.8073 3.27273 16.0253V17.6051C3.27273 17.8231 3.456 18 3.68182 18H14.3182C14.544 18 14.7273 17.8231 14.7273 17.6051V16.0253C14.7273 15.8073 14.544 15.6304 14.3182 15.6304H13.5V14.9344C13.5 14.7164 13.3167 14.5395 13.0909 14.5395H4.90909C4.68327 14.5395 4.5 14.7164 4.5 14.9344V15.6304ZM7.36486 11.8196C7.36405 11.8291 7.36364 11.8386 7.36364 11.8481C7.36364 13.666 7.00364 12.2951 5.55545 13.7496H12.4445C10.9964 12.2951 10.6364 13.666 10.6364 11.8481C10.6364 11.8386 10.636 11.8291 10.6351 11.8196C11.036 11.6127 11.4194 11.3398 11.7806 11.0207C12.9387 9.9974 13.8727 8.50454 14.4515 7.42321C14.452 7.42321 14.452 7.42321 14.452 7.42281C14.9003 6.58358 15.1343 5.65271 15.1343 4.70803C15.1364 3.0805 15.1364 0.394936 15.1364 0.394936C15.1364 0.176931 14.9531 0 14.7273 0H3.27273C3.04691 0 2.86364 0.176931 2.86364 0.394936V4.70803C2.86364 5.65311 3.09764 6.58397 3.546 7.424C3.546 7.424 3.54641 7.42439 3.54641 7.42479C4.12691 8.50494 5.06086 9.99779 6.21941 11.0207C6.58064 11.3398 6.96395 11.6131 7.36486 11.8196ZM15.9545 1.18481H17.5909C17.8167 1.18481 18 1.36174 18 1.57974V4.34429C18 5.39166 17.5688 6.39638 16.8018 7.13688C16.2147 7.70401 15.4669 8.08078 14.6622 8.22611C14.8545 7.94373 15.0271 7.67241 15.1785 7.42321C15.1785 7.42321 15.1789 7.42321 15.1789 7.42281C15.687 6.58358 15.9525 5.65271 15.9525 4.70803C15.9537 3.63617 15.9541 2.10501 15.9545 1.18481ZM2.04545 1.18481V4.70803C2.04545 5.65311 2.31054 6.58397 2.81864 7.424C2.81904 7.424 2.81905 7.42439 2.81945 7.42479C2.97082 7.6732 3.14345 7.94413 3.33573 8.22572C2.53145 8.07999 1.78486 7.70322 1.19823 7.13688C0.431182 6.39638 0 5.39166 0 4.34429V1.57974C0 1.36174 0.183273 1.18481 0.409091 1.18481H2.04545Z" fill="#7A7A99" />
        </svg>
    );
};

export default TopUsersIcon;
