import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { LootContextProvider } from "../context/LootContextProvider";
import { CoinContextProvider } from "../context/CoinContextProvider";

const LastLoot = () => {
    const { lootData } = useContext(LootContextProvider);
    const { coinData } = useContext(CoinContextProvider);

    const [itemsToShow, setItemsToShow] = useState(calculateItemsToShow());

    useEffect(() => {
        function handleResize() {
            const updatedItemsToShow = calculateItemsToShow();
            setItemsToShow(updatedItemsToShow);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function calculateItemsToShow() {
        const screenWidth = window.innerWidth;
        const itemWidthInPixels = 103; // .options-item { width: 103px; }
        const itemsToFitOnTheScreen = Math.floor(screenWidth / itemWidthInPixels) - 1;
        return Math.min(Math.max(itemsToFitOnTheScreen, 1), 25); // max 25
    }

    return (
        <div className="second-row">
            {/* <div className="second-row-1st-button">
                <div className="top-part">
                    <img src="/img/layout-2nd-row/rhomb-small.svg" alt="" />
                </div>
                <div className="bottom-part">
                    <img src="/img/layout-2nd-row/square.svg" alt="" />
                </div>
            </div> */}
            <ul className="options">
                {lootData && coinData &&
                    lootData.loot.slice(0, itemsToShow).map(({ _id, username, coin, caseSlug, amount }) => {
                        const symbolOfCoin = coinData.find(({ symbol }) => symbol === coin);
                        const coinColor = symbolOfCoin ? symbolOfCoin.hexColor : '#fff'; // default color

                        return (
                            <Link to={`/user/${username}`} key={_id}>
                                <li className="options-item">
                                    <img
                                        className="main-icon"
                                        src={`/images/logos/${coin.toLowerCase()}.png`}
                                        alt=""
                                    />
                                    <p>{parseFloat(amount.$numberDecimal).toFixed(2)}$</p>
                                    <div className="options-item-border" style={{ backgroundColor: coinColor }}></div>
                                </li>
                            </Link>
                        );
                    })
                }
            </ul>
        </div>
    )
}

export default LastLoot