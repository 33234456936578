import { useRef, useState, useContext } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { UserContextProvider } from "../context/UserContextProvider";
import { useLanguageContext } from "../context/languageContext";
import toast from "react-hot-toast";
import CloseModalIcon from "../assets/svg/CloseModalIcon";
import useDetectOutsideClick from "../hooks/useDetectOutsideClick";

const ActivateTelegram = ({ isTelegramActivationModalVisible, handleShowTelegramActivationModal }) => {
    const modalRef = useRef(null);
    const axiosPrivate = useAxiosPrivate();
    const { t } = useLanguageContext();
    const { userData, updateUserData } = useContext(UserContextProvider);
    const botUsername = process.env.REACT_APP_MODE === 'PROD' ? 'clickup_verification_bot' : 'cryptocases_verification_bot';

    const [code, setCode] = useState('');

    useDetectOutsideClick(modalRef, () => {
        handleCloseTelegramActivationModal();
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        try {
            const response = await axiosPrivate.put('/me/activate',
                JSON.stringify({ code }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setCode('');
            updateUserData();
            toast.success("Telegram activated successfully")
            handleCloseTelegramActivationModal();
        } catch (err) {
            if (!err?.response) {
                toast.error('No Server Response');
            } else if (err.response?.status === 400) {
                toast.error(`${JSON.stringify(err.response?.data.message).replace(/"/g, '')}`);
            } else {
                toast.error('Telegram activation failed')
            }
        }
    }

    const handleCloseTelegramActivationModal = () => {
        handleShowTelegramActivationModal(false);
        setCode('');
    };

    return (
        <>
            {isTelegramActivationModalVisible ? (
                <div className="any-modal-background">
                    <div className="any-modal flex column" ref={modalRef}>
                        <div className="flex space-between">
                            <h1>{t("Заголовок 1: Активация Telegram")}</h1>
                            <div className="close-modal-icon" onClick={handleCloseTelegramActivationModal}><CloseModalIcon /></div>
                        </div>
                        <form onSubmit={handleSubmit} className="activate-telegram flex column">
                            <p>{t("Текст 1: Активация Telegram")}</p>
                            <a href={`https://t.me/${botUsername}`} target="_blank"><div className="bot-username">@{botUsername}</div></a>
                            <p>{t("Текст 2: Активация Telegram")}</p>
                            <p className="hint telegram">{t("Текст 3: Активация Telegram")}</p>
                            <input
                                type="text"
                                id="code"
                                placeholder="123456"
                                autoComplete="off"
                                onChange={(e) => setCode(e.target.value)}
                                value={code}
                                required
                            />
                            <button disabled={!code}>{t("Кнопка 1: Активация Telegram")}</button>
                        </form>
                    </div>
                </div>
            ) : (
                null
            )}
        </>
    )
}

export default ActivateTelegram