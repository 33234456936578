import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Loading from "./Loading";

const InvitedBylink = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        redirectToHome();
    }, []);

    function redirectToHome() {
        const refCodeFromInviteLink = location.pathname.split('/').pop();
        localStorage.setItem("ref-code-from-invite-link", refCodeFromInviteLink);
        navigate('/');
    }

    return (
        <Loading />
    )
}

export default InvitedBylink