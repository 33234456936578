import { useState, useEffect, useRef } from "react";
import { useLanguageContext } from "../context/languageContext";
import axios from '../api/axios';
import toast from "react-hot-toast";
import CloseModalIcon from "../assets/svg/CloseModalIcon";
import useDetectOutsideClick from '../hooks/useDetectOutsideClick';

const USER_REGEX = /^[A-Za-z0-9][A-Za-z0-9-_]{2,23}$/;
const TELEGRAM_REGEX = /^[A-Za-z0-9][A-Za-z0-9-_]{2,30}$/;
const PWD_REGEX = /^.{8,24}$/;
const REGISTER_URL = '/register';

const Register = ({ handleShowRegisterScreen, handleCloseLoginModal }) => {
    const { t } = useLanguageContext();
    const modalRef = useRef(null);
    const refCode = localStorage.getItem('ref-code-from-invite-link') || '';

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);

    const [telegram, setTelegram] = useState('');
    const [validTelegram, setValidTelegram] = useState(false);

    const [inviteCode, setInviteCode] = useState(refCode);

    const [success, setSuccess] = useState(false);

    useDetectOutsideClick(modalRef, () => {
        handleCloseLoginModal();
    });

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setValidTelegram(TELEGRAM_REGEX.test(telegram));
    }, [telegram])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        const v3 = TELEGRAM_REGEX.test(telegram);
        if (!v1 || !v2 || !v3) {
            toast.error("Invalid Entry");
            return;
        }
        let userData = { user, pwd, telegram };
        if (inviteCode) {
            userData = { user, pwd, telegram, inviteCode };
        }
        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify(userData),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setSuccess(true);
            setUser('');
            setPwd('');
            setMatchPwd('');
            setTelegram('');
        } catch (err) {
            if (!err?.response) {
                toast.error('No server response. Please, contact the admin.');
            } else if (err.response?.status === 409) {
                toast.error('This username or Telegram already taken');
            } else {
                toast.error(`${JSON.stringify(err.response?.data.message).replace(/"/g, '')}`);
            }
        }
    }

    const handleSwichRegisterToLoginScreen = () => {
        handleShowRegisterScreen(false);
    };

    const handleTelegramChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.startsWith('@')) {
            setTelegram(inputValue.slice(1));
        } else {
            setTelegram(inputValue);
        }
    };

    return (
        <div className="any-modal flex column" ref={modalRef}>
            {success ? (
                <div>
                    <h1>{t("Заголовок 4: Вход")}</h1>
                    <br />
                    <button onClick={handleSwichRegisterToLoginScreen}>{t("Кнопка 1: Вход")}</button>
                </div>
            ) : (
                <div>
                    <div className="flex space-between">
                        <h1>{t("Заголовок 5: Вход")}</h1>
                        <div className="close-modal-icon" onClick={handleCloseLoginModal}><CloseModalIcon /></div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <p>{t("Заголовок 2: Вход")}</p>
                        <input
                            type="text"
                            id="username"
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value.toLowerCase())}
                            value={user}
                            required
                            placeholder="clickup"
                        />
                        <label htmlFor="username">
                            {validName || !user ? null : <div className="input-assist">{t("Текст 5: Вход")}</div>}
                        </label>

                        <p>{t("Заголовок 3: Вход")}</p>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                            placeholder="*********"
                            autoComplete="new-password"
                        />
                        <label htmlFor="password">
                            {validPwd || !pwd ? null : <div className="input-assist">{t("Текст 6: Вход")}</div>}
                        </label>

                        <p>{t("Заголовок 6: Вход")}</p>
                        <input
                            type="password"
                            id="confirm_pwd"
                            onChange={(e) => setMatchPwd(e.target.value)}
                            value={matchPwd}
                            required
                            placeholder="*********"
                            autoComplete="new-password"
                        />
                        <label htmlFor="confirm_pwd">
                            {validMatch || !matchPwd ? null : <div className="input-assist">{t("Текст 7: Вход")}
                            </div>}
                        </label>

                        <p>{t("Заголовок 7: Вход")}</p>
                        <label htmlFor="telegram"></label>
                        <input
                            type="text"
                            id="telegram"
                            autoComplete="off"
                            onChange={handleTelegramChange}
                            value={`@${telegram}`}
                            required
                            placeholder="@clickup"
                        />
                        <label htmlFor="telegram">
                            {validTelegram || !telegram ? null : <div className="input-assist">{t("Текст 8: Вход")}</div>}
                        </label>

                        <p>{t("Заголовок 8: Вход")}</p>
                        <label htmlFor="inviteCode"></label>
                        <input
                            className="caps-lock"
                            type="text"
                            id="inviteCode"
                            autoComplete="off"
                            onChange={(e) => setInviteCode(e.target.value.toUpperCase())}
                            value={inviteCode}
                            placeholder={refCode ? refCode : "TORP5M0AT6"}
                        />
                        <span className="agreement-note">Регистрируясь, вы подтверждаете <a href="/agreement" target="_blank">пользовательское соглашение</a></span>
                        <button className="register" disabled={!validName || !validPwd || !validMatch || !validTelegram ? true : false}>{t("Кнопка 4: Вход")}</button>
                    </form>
                    <div className="flex space-between center">
                        {t("Текст 4: Вход")}
                        <button className="small" onClick={handleSwichRegisterToLoginScreen}>{t("Заголовок 1: Вход")}</button>
                    </div>
                </div>
            )
            }
        </div >
    )
}

export default Register
