import CasesTable from "./CasesTable"
import LastLoot from "./LastLoot"

const Cases = () => {

    return (
        <div>
            <LastLoot />
            <CasesTable />
        </div>
    )
}

export default Cases