import { useLanguageContext } from "../context/languageContext";

const LanguageSelect = ({ parentClassName }) => {
    const { defaultLanguage, onClickLanguageChangeToggle } = useLanguageContext();
    const selectedLanguage = localStorage.getItem('cases-lang');

    return (
        <>
            {/* Toggle version */}
            <div className={parentClassName} onClick={() => onClickLanguageChangeToggle(selectedLanguage)}>
                <img src={`/img/header/${selectedLanguage}.svg`} alt="" />
                <p>{defaultLanguage.toUpperCase()}</p>
            </div>
            {/* Dropdown select version */}
            {/* <select
                onChange={onClickLanguageChange}
                defaultValue={selectedLanguage}
            >
                {Object.keys(languages).map((lng) => (
                    <option key={languages[lng].nativeName} value={lng}>
                        {languages[lng].nativeName}
                    </option>
                ))}
            </select> */}
        </>
    );
};

export default LanguageSelect;
