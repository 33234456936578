import { useLanguageContext } from "../context/languageContext";
import { Link } from "react-router-dom"

const Games = () => {
    const { t } = useLanguageContext();

    return (
        <>
            <div className="main-games flex center">
                <h1>{t("Заголовок 4: Игры")}</h1>

                <div className="games-wrapper">
                    <Link to="cases">
                        <div className="game-item game-item-left">
                            <img className="orange-ball-bg" src="/img/main-games/light-2.png" alt="" />
                            <img className="game-item-left-coin-icon-1" src="/img/main-games/coins.png" alt="" />
                            <img className="game-item-left-coin-icon-2" src="/img/main-games/blurred-coin-big.png" alt="" />
                            <h3>{t("Заголовок 1: Игры")}</h3>

                            <div className="game-item-left-img-wrapper">
                                <div className="game-item-left-img">
                                    <img className="catalog-item-open-box-flicker" src="/img/main-content/box-flicker.webp" alt="" />
                                    <img className="catalog-item__thumbnail" src="/img/main-content/box-opened.gif" alt="" />
                                    <img className="catalog-item__thumbnail-static" src="/img/main-content/box-opened-static.png" alt="" />
                                    <img className="game-item-left-open-box-light" src="/img/main-content/box-light.png" alt="" />
                                </div>
                            </div>


                            <div className="game-item-left-button-white">
                                <img src="/img/main-games/game-controller.svg" alt="" />
                                <p>{t("Кнопка 1: Игры")}</p>
                            </div>

                        </div>
                    </Link>


                    <div className="game-item game-item-mid">
                        <img className="game-item-mid-coin-icon-1" src="/img/main-games/coins.png" alt="" />
                        <img className="game-item-mid-coin-icon-2" src="/img/main-games/blurred-coins.png" alt="" />

                        <img className="game-item-mid-img" src="/img/main-games/plane.gif" alt="" />
                        <img className="game-item-mid-img-static" src="/img/main-games/plane-static.gif" alt="" />
                        <img className="game-item-mid-bgs orange-circle-bg" src="/img/main-games/Focus2-02.webp" alt="" />
                        <img className="game-item-mid-bgs orange-flicker-bg" src="/img/main-games/Effects_Lightcolor_03.png" alt="" />
                        <img className="game-item-mid-bgs white-pattern-bg" src="/img/main-games/games-pattern.webp" alt="" />
                        <div className="game-item-mid-bgs red-shining-mid red-shining"></div>
                        <h3>{t("Заголовок 2: Игры")}</h3>
                        <div className="game-item-mid-img-wrapper">

                        </div>
                        <div className="game-item-button-orange game-item-mid-button">
                            <p>{t("Кнопка 2: Игры")}</p>
                        </div>
                    </div>
                    <div className="game-item game-item-right">
                        <img className="game-item-right-coin-icon-1" src="/img/main-games/blurred-coins.png" alt="" />
                        <img className="game-item-right-coin-icon-2" src="/img/main-games/coins.png" alt="" />
                        <img className="game-item-right-coin-icon-3" src="/img/main-games/coins.png" alt="" />


                        <h3>{t("Заголовок 3: Игры")}</h3>
                        <img className="game-item-right-bgs grey-triangle-bg" src="/img/main-games/grey-triangle.svg" alt="" />
                        <img className="game-item-right-bgs orange-flicker-bg" src="/img/main-games/Effects_Lightcolor_03.webp" alt="" />
                        <div className="game-item-right-bgs red-shining-right red-shining"></div>
                        <img className="game-item-right-bgs orange-triangle-bg" src="/img/main-games/orange-triangle.svg" alt="" />
                        <img className="game-item-right-bgs eye-bg" src="/img/main-games/predictions.webp" alt="" />
                        <img className="game-item-right-bgs wave-bg" src="/img/main-games/wave-bg.png" alt="" />
                        <img className="game-item-right-bgs btc-icon-bg" src="/img/main-games/bitcoin-icon.svg" alt="" />


                        <div className="game-item-button-orange game-item-right-button">
                            <p>{t("Кнопка 2: Игры")}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default Games