import React from 'react';

const TryAgainIcon = () => {
    return (
        <svg width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M23.3948 22.1767L11.3693 15.2338V29.1196L23.3948 22.1767ZM11.6365 7.74609L23.662 14.689V0.803182L11.6365 7.74609ZM12.5718 20.9741H8.42974V23.3792H12.5718V20.9741ZM2.68424 14.9614C2.68424 11.5975 5.29896 8.94864 8.42974 8.94864V6.54355C3.88592 6.54355 0.279142 10.3555 0.279142 14.9614H2.68424ZM8.42974 20.9741C5.29896 20.9741 2.68424 18.3253 2.68424 14.9614H0.279142C0.279142 19.5672 3.88592 23.3792 8.42974 23.3792V20.9741ZM22.4595 8.94864H26.6016V6.54355H22.4595V8.94864ZM32.3471 14.9614C32.3471 18.3253 29.7324 20.9741 26.6016 20.9741V23.3792C31.1454 23.3792 34.7522 19.5672 34.7522 14.9614H32.3471ZM26.6016 8.94864C29.7324 8.94864 32.3471 11.5975 32.3471 14.9614H34.7522C34.7522 10.3555 31.1454 6.54355 26.6016 6.54355V8.94864Z" fill="white" />
        </svg>
    );
};

export default TryAgainIcon;
