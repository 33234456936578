// TODO: add isLastPage everywhere

import { useState, useContext, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { UserContextProvider } from "../context/UserContextProvider";
import { useLanguageContext } from "../context/languageContext";
import toast from "react-hot-toast";

const Withdraw = () => {
    const axiosPrivate = useAxiosPrivate();
    const { updateUserData } = useContext(UserContextProvider);
    const { t } = useLanguageContext();

    const [sum, setSum] = useState('');
    const [network, setNetwork] = useState('TRON (TRC-20)');
    const [address, setAddress] = useState('');
    const [transactions, setTransactions] = useState();
    const [startingTimestamp, setStartingTimestamp] = useState();
    const [isLastPage, setIsLastPage] = useState(false);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        getTransactionsTable();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        try {
            const response = await axiosPrivate.post('/me/withdraw',
                JSON.stringify({ sum, network, address }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setSum('');
            setAddress('');
            updateUserData();
        } catch (err) {
            if (!err?.response) {
                toast.error('No Server Response');
            } else if (err.response?.status === 400) {
                toast.error(`${JSON.stringify(err.response?.data.message).replace(/"/g, '')}`);
            } else {
                toast.error('Withdraw failed. Please contact the support.')
            }
        } finally {
            window.location.reload();
        }
    }

    const getTransactionsTable = async () => {
        try {
            const response = await axiosPrivate.get('/me/withdrawals', {
                params: {
                    startingTimestamp
                }
            });
            const moreTransactions = response.data.transactions
            setTransactions(transactions ? [...transactions, ...moreTransactions] : moreTransactions)
            setStartingTimestamp(response.data.pagination.nextPage)
            if (response.data.pagination.isLastPage === true) {
                setIsLastPage(true)
            }
        } catch (err) {
            console.error("Error fetching tx table", err);
            setIsLastPage(true)
        }
    }

    const prettifyTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).replace(',', '');
    };

    return (
        <>
            <div className="new-withdraw flex column center">
                <h1>{t("Заголовок 1: Вывод средств")}</h1>
                <form className="flex column" onSubmit={handleSubmit}>
                    <div className="sum-and-network flex row">
                        <div className="sum flex column">
                            <label className="sum">{t("Текст 1: Вывод средств")}</label>
                            <input
                                type="number"
                                step="0.01"
                                id="sum"
                                autoComplete="off"
                                onChange={(e) => setSum(e.target.value)}
                                value={sum}
                                required
                                min="1"
                                placeholder="99.99"
                            />
                        </div>
                        <div className="network flex column">
                            <label className="network">{t("Текст 2: Вывод средств")}</label>
                            <select
                                className="rounded-arrow"
                                name="network"
                                id="network"
                                onChange={(e) => setNetwork(e.target.value)}
                                required
                            >
                                <option>TRON (TRC-20)</option>
                                <option>BSC (BEP-20)</option>
                            </select>
                        </div>
                    </div>

                    <label className="address">{t("Текст 3: Вывод средств")}</label>
                    <input
                        type="text"
                        id="address"
                        // autoComplete="off"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                        required
                        placeholder="0xc0ffee254729296a45a3885639AC7E10F9d54979"
                    />

                    <button disabled={!sum || !address}>{t("Кнопка 1: Вывод средств")}</button>
                </form>
            </div>

            {transactions ? (
                <div className="withdrawal-history-block flex center">
                    <div className="withdrawal-history">
                        <h3 className="flex center">{t("Заголовок 2: Вывод средств")}</h3>
                        <div className="users-list-table my-withdrawals">
                            <table className="users-list">
                                <thead className="users-list-header">
                                    <tr>
                                        <th className="users-list-header__time-column">{t("Таблица 1: Вывод средств")}</th>
                                        <th className="users-list-header__network-column">{t("Таблица 2: Вывод средств")}</th>
                                        <th className="users-list-header__sum-column">{t("Таблица 3: Вывод средств")}</th>
                                        <th className="users-list-header__sum-column">{t("Таблица 4: Вывод средств")}</th>
                                        <th className="users-list-header__status-column">{t("Таблица 5: Вывод средств")}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {transactions
                                        .filter(({ fee }) => fee)
                                        .map(({ timestamp, sumTransferred, network, to, fee, status, explorerLink }) => {
                                            const shortenedTo = `${to.substring(0, 4)}...${to.substring(to.length - 4)}`;

                                            return (
                                                <tr className="users-list-user" key={timestamp}>
                                                    <td className="users-list-user__time-column"><p>{prettifyTimestamp(timestamp)}</p></td>
                                                    <td className="users-list-user__network-column"><p>{shortenedTo}<br /><span>{network}</span></p></td>
                                                    <td className="users-list-user__sum-withdraw-column"><p>${parseFloat(sumTransferred.$numberDecimal).toFixed(2)}</p></td>
                                                    <td className="users-list-user__sum-fee-column"><p>${parseFloat(fee.$numberDecimal).toFixed(2)}</p></td>
                                                    <td className="users-list-user__link-column">
                                                        <p style={{
                                                            color:
                                                                status === 'success' ? '#88ff33' :
                                                                    status === 'processing' ? '#ff6c33' :
                                                                        status === 'cancelled' ? '#383855' : '#383855'
                                                        }}>
                                                            {status === 'success' ? (
                                                                <a href={explorerLink} target="_blank" className="success-status-link">
                                                                    {t("Таблица 6: Вывод средств")}
                                                                </a>
                                                            ) : status === 'processing' ? (
                                                                t("Таблица 7: Вывод средств")
                                                            ) : status === 'cancelled' ? (
                                                                t("Таблица 8: Вывод средств")
                                                            ) : (
                                                                t(status)
                                                            )}
                                                        </p>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                            {!isLastPage && (
                                <div className="flex center">
                                    <button onClick={getTransactionsTable}>{t("Кнопка 1: Профиль пользователя")}</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                null
            )
            }
        </>
    )
}

export default Withdraw
