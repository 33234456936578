import React from 'react';

const GamesIcon = () => {
    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="GamesIcon" d="M19.2165 5.34231C18.7835 2.27719 16.1866 0 13.1241 0H6.87545C3.81339 0 1.21696 2.27719 0.783482 5.34231C0.734375 5.69144 0 10.7629 0 11.116C0 12.7085 1.27723 14 2.85223 14C3.93304 14 4.92009 13.3827 5.40357 12.4053L7.14286 9.1H12.8571L14.5964 12.4053C15.0795 13.3827 16.067 14 17.1478 14C18.7228 14 20 12.7085 20 11.116C20 10.7634 19.2656 5.69187 19.2165 5.34231ZM14.2857 2.1C14.8116 2.1 15.2379 2.51781 15.2379 3.03319C15.2379 3.54856 14.8116 3.96638 14.2857 3.96638C13.7598 3.96638 13.3335 3.54856 13.3335 3.03319C13.3335 2.51781 13.7598 2.1 14.2857 2.1ZM7.85714 5.6H6.42857V7H5V5.6H3.57143V4.2H5V2.8H6.42857V4.2H7.85714V5.6ZM12.3808 5.83319C11.8549 5.83319 11.4286 5.41537 11.4286 4.9C11.4286 4.38462 11.8549 3.96681 12.3808 3.96681C12.9067 3.96681 13.333 4.38462 13.333 4.9C13.3339 5.41537 12.9071 5.83319 12.3808 5.83319ZM14.2857 7.7C13.7598 7.7 13.3335 7.28219 13.3335 6.76681C13.3335 6.25144 13.7598 5.83362 14.2857 5.83362C14.8116 5.83362 15.2379 6.25144 15.2379 6.76681C15.2379 7.28219 14.8116 7.7 14.2857 7.7ZM16.1906 5.83319C15.6647 5.83319 15.2384 5.41537 15.2384 4.9C15.2384 4.38462 15.6647 3.96681 16.1906 3.96681C16.7165 3.96681 17.1429 4.38462 17.1429 4.9C17.1429 5.41537 16.7165 5.83319 16.1906 5.83319Z" fill="#7A7A99" />
        </svg>
    );
};

export default GamesIcon;
