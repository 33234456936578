import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

export const LanguageContext = createContext(undefined);

export const LanguageContextProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const savedLanguage = localStorage.getItem("cases-lang");
  const defaultLanguage = savedLanguage || "ru";

  const languages = {
    ru: { nativeName: "RU" },
    en: { nativeName: "EN" },
  };
  const { t, i18n } = useTranslation();

  // Initial language from the storage
  useEffect(() => {
    if (!initialized) {
      i18n.changeLanguage(defaultLanguage);
      setInitialized(true);
      localStorage.setItem("cases-lang", defaultLanguage);
    }
  }, [initialized, defaultLanguage, i18n]);

  const onClickLanguageChange = (e) => {
    const language = e.target.value;
    i18n.changeLanguage(language);
    localStorage.setItem("cases-lang", language);
  };

  const onClickLanguageChangeToggle = (selectedLanguage) => {
    const language = selectedLanguage === "ru" ? "en" : "ru";
    i18n.changeLanguage(language);
    localStorage.setItem("cases-lang", language);
  };

  return (
    <LanguageContext.Provider
      value={{
        t,
        i18n,
        onClickLanguageChange,
        onClickLanguageChangeToggle,
        languages,
        defaultLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);
