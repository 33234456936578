import { useState, useEffect } from "react";
import axios from "../api/axios";
import { useLanguageContext } from "../context/languageContext";
import Loading from "./Loading";

const LiveWithdrawals = () => {
    const { t } = useLanguageContext();

    const [lastWithdrawals, setLastWithdrawals] = useState();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        getLastWithdrawals();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const getLastWithdrawals = async () => {
        try {
            const response = await axios.get('/lastwithdrawals');
            setLastWithdrawals(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    const prettifyTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).replace(',', '');
    };

    return (
        <div>
            {lastWithdrawals ? (
                <div className="top-users-block flex center">
                    <div className="top-users-content">
                        <div className="top-users-header">
                            <h1>{t("Заголовок: LIVE выводы")}</h1>
                            <div className="withdrawals-intro">{t("Текст: LIVE выводы")}</div>
                        </div>
                        <div className="users-list-table">
                            <table className="users-list">
                                <thead className="users-list-header">
                                    <tr>
                                        <th className="users-list-header__users-column">{t("Таблица 1: LIVE выводы")}</th>
                                        <th className="users-list-header__sum-column">{t("Таблица 2: LIVE выводы")}</th>
                                        <th className="users-list-header__network-column">{t("Таблица 3: LIVE выводы")}</th>
                                        <th className="users-list-header__time-column">{t("Таблица 4: LIVE выводы")}</th>
                                        <th className="users-list-header__link-column">{t("Таблица 5: LIVE выводы")}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {lastWithdrawals.map(({ from, network, timestamp, sumTransferred, explorerLink, _id }) => (
                                        <tr className="users-list-user" key={_id}>
                                            <td className="users-list-user__users-column">
                                                <img src="/img/main-content/user-avatar.svg" alt="" />
                                                <p>{from}</p>
                                            </td>
                                            <td className="users-list-user__sum-column"><p>${parseFloat(sumTransferred.$numberDecimal).toFixed(2)}</p></td>
                                            <td className="users-list-user__network-column"><p>{network}</p></td>
                                            <td className="users-list-user__time-column"><p>{prettifyTimestamp(timestamp)}</p></td>
                                            <td className="users-list-user__link-column">
                                                <a href={explorerLink} target="_blank">
                                                    <img src="/img/withdrawals/external-link.svg" alt="" />
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : <Loading />
            }
        </div >
    )
}

export default LiveWithdrawals