import { useState, useContext, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLanguageContext } from "../context/languageContext";
import { UserContextProvider } from "../context/UserContextProvider";
import toast from "react-hot-toast";
import useDetectOutsideClick from "../hooks/useDetectOutsideClick";
import CloseModalIcon from "../assets/svg/CloseModalIcon";

const Deposit = ({ isDepositModalVisible, handleShowDepositModal }) => {
    const axiosPrivate = useAxiosPrivate();
    const { t } = useLanguageContext();
    const { updateUserData } = useContext(UserContextProvider);
    const modalRef = useRef(null);

    const [sum, setSum] = useState('');
    const [promocode, setPromocode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentCreated, setIsPaymentCreated] = useState(false);
    const [promocodeBonusAmount, setPromocodeBonusAmount] = useState('');
    const [uuid, setUuid] = useState('');
    const [showPayButton, setShowPayButton] = useState(false);

    useDetectOutsideClick(modalRef, () => {
        handleCloseDepositModal();
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        try {
            setIsLoading(true);
            const response = await axiosPrivate.put('/me/deposit',
                JSON.stringify({ sum, promocode }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            const uuid = response.data.uuidFromInvoiceResponse
            const promoSumToAdd = response.data.promoSumToAdd

            setUuid(uuid);

            if (promoSumToAdd === 0) {
                setShowPayButton(true);
            } else if (promoSumToAdd > 0) {
                setPromocodeBonusAmount(promoSumToAdd);
                setIsLoading(false);
                setShowPayButton(true);
            }
        } catch (err) {
            if (!err?.response) {
                toast.error("No server response. Please, contact support.")
            }
            else {
                toast.error(`${JSON.stringify(err.response?.data.message).replace(/"/g, '')}`);
            }
            setIsLoading(false);
        }
    }

    const handleSumChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.startsWith('$')) {
            setSum(inputValue.slice(1));
        } else {
            setSum(inputValue);
        }
    };

    const handleCloseDepositModal = () => {
        handleShowDepositModal(false);
        setSum('');
        setPromocode('');
        setIsLoading(false);
        setIsPaymentCreated(false);
        setPromocodeBonusAmount('');
        setUuid('');
        setShowPayButton(false);
        updateUserData();
    };

    return (
        <>
            {isDepositModalVisible ? (
                <div className="any-modal-background">
                    <div className="any-modal flex column" ref={modalRef}>
                        <div className="flex space-between">
                            <h1>{t("Заголовок 2: Пополнить баланс")}</h1>
                            <div className="close-modal-icon" onClick={handleCloseDepositModal}><CloseModalIcon /></div>
                        </div>
                        {isPaymentCreated ? (
                            <div className="after-payment-created">
                                <p>{t("Текст 3: Пополнить баланс")}</p>
                                <p className="hint">{t("Текст 2: Пополнить баланс")}</p>
                                <button onClick={handleCloseDepositModal}>{t("Кнопка 3: Пополнить баланс")}</button>
                            </div>
                        ) : (
                            <>
                                <form onSubmit={handleSubmit} className="flex column">
                                    <input
                                        type="text"
                                        id="sum"
                                        placeholder={t("Форма 1: Пополнить баланс")}
                                        autoComplete="off"
                                        onChange={handleSumChange}
                                        value={`$${sum}`}
                                        required
                                        readOnly={uuid}
                                    />
                                    {promocodeBonusAmount > 0 && (
                                        <input
                                            className="promo-bonus"
                                            type="text"
                                            id="sum"
                                            autoComplete="off"
                                            value={`${t("Форма 3: Пополнить баланс")} $${promocodeBonusAmount} ${t("Форма 4: Пополнить баланс")}`}
                                            readOnly
                                        />
                                    )}
                                    <input
                                        type="text"
                                        id="promocode"
                                        placeholder={t("Форма 2: Пополнить баланс")}
                                        onChange={(e) => setPromocode(e.target.value.toUpperCase())}
                                        value={promocode}
                                        readOnly={uuid}
                                    />
                                    {showPayButton ? (
                                        <a href={`https://pay.cryptocloud.plus/${uuid}`} target="_blank" onClick={() => setIsPaymentCreated(true)}>
                                            <button type="button" className="external">{t("Кнопка 4: Пополнить баланс")}</button>
                                        </a>
                                    ) : (
                                        <button type="submit" disabled={!sum || isLoading || showPayButton}>
                                            {isLoading ? t("Кнопка 2: Пополнить баланс") :
                                                promocode ? t("Кнопка 5: Пополнить баланс") :
                                                    t("Кнопка 1: Пополнить баланс")
                                            }
                                        </button>
                                    )}
                                </form>

                                <p>{t("Текст 1: Пополнить баланс")}</p>
                                <p className="hint">{t("Текст 2: Пополнить баланс")}</p>
                            </>
                        )
                        }
                    </div>
                </div >
            ) : (
                null
            )}
        </>
    )
}

export default Deposit