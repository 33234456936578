// TODO: Optmize all images

import { Routes, Route } from 'react-router-dom';
import './scss/app.scss'
import Layout from './components/Layout';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import Deposit from "./components/Deposit";
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import Cases from './components/Cases';
import CasesOpening from './components/CasesOpening';
import LiveWithdrawals from './components/LiveWithdrawals';
import Help from './components/Help';
import TopUsers from './components/TopUsers';
import Referral from './components/Referral';
import ActivateTelegram from './components/ActivateTelegram';
import UserProfile from './components/UserProfile';
import MyProfile from './components/MyProfile';
import Withdraw from './components/Withdraw';
import ChangePassword from './components/ChangePassword';
import Games from './components/Games';
import InvitedBylink from './components/InvitedByLink';
import Agreement from './components/Agreement';

const ROLES = {
  'User': 2001
}

function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Games />} />
        <Route path="cases" element={<Cases />} />
        <Route path="cases/:slug" element={<CasesOpening />} />
        <Route path="user/:username" element={<UserProfile />} />
        <Route path="top" element={<TopUsers />} />
        <Route path="withdrawals" element={<LiveWithdrawals />} />
        <Route path="help" element={<Help />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="referral" element={<Referral />} />
        <Route path="invite/:refcode" element={<InvitedBylink />} />
        <Route path="agreement" element={<Agreement />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="me" element={<MyProfile />} />
            <Route path="deposit" element={<Deposit />} />
            <Route path="activate-telegram" element={<ActivateTelegram />} />
            <Route path="withdraw" element={<Withdraw />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;