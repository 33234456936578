import { useLanguageContext } from "../context/languageContext";

const AboutReferral = () => {
    const { t } = useLanguageContext();

    return (
        <div className="referral-block flex column center">
            <h1>{t("Заголовок: Реферальная система")}</h1>
            <div className="referral-intro flex column center">
                <p>{t("Текст 1: Реферальная система")}</p>
                <p>{t("Текст 2: Реферальная система")}</p>
                <p>{t("Текст 3: Реферальная система")}</p>
            </div>

            <div className="percent-block flex row center">

                <div className="flames-block flex row">
                    <img className="flame-one" src="/img/referral/flame1.png" />
                    <img className="flame-two" src="/img/referral/flame2.png" />
                    <img className="flame-three" src="/img/referral/flame3.png" />
                </div>

                <div className="level-blocks flex row">
                    <div className="level-block one">
                        <div className="level-share">5%</div>
                        <div className="level-number">1 lvl</div>
                    </div>
                    <div className="level-block two">
                        <div className="level-share">3%</div>
                        <div className="level-number">2 lvl</div>
                    </div>
                    <div className="level-block three">
                        <div className="level-share">2%</div>
                        <div className="level-number">3 lvl</div>
                    </div>
                    <div className="level-block four">
                        <div className="level-share">1%</div>
                        <div className="level-number">4 lvl</div>
                    </div>
                    <div className="level-block five">
                        <div className="level-share">1%</div>
                        <div className="level-number">5 lvl</div>
                    </div>
                    <div className="level-block six">
                        <div className="level-share">1%</div>
                        <div className="level-number">6 lvl</div>
                    </div>
                    <div className="level-block seven">
                        <div className="level-share">0.5%</div>
                        <div className="level-number">7 lvl</div>
                    </div>
                    <div className="level-block eight">
                        <div className="level-share">0.5%</div>
                        <div className="level-number">8 lvl</div>
                    </div>
                    <div className="level-block nine">
                        <div className="level-share">0.5%</div>
                        <div className="level-number">9 lvl</div>
                    </div>
                    <div className="level-block ten">
                        <div className="level-share">0.5%</div>
                        <div className="level-number">10 lvl</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutReferral