import axios from 'axios';

const BASE_URI = process.env.REACT_APP_MODE === 'PROD' ? 'https://api.clickup.bet' : 'http://localhost:3500';

export default axios.create({
    baseURL: BASE_URI
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URI,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});