import MyData from "./MyData"
import { useLanguageContext } from "../context/languageContext";

const MyProfile = () => {
    const { t } = useLanguageContext();
    return (
        <>
            <MyData />
        </>
    )
}

export default MyProfile