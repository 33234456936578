// TODO: Add last page mark to API response and hide button from the start then

import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "../api/axios";
import { useLanguageContext } from "../context/languageContext";
import { CoinContextProvider } from "../context/CoinContextProvider";
import Loading from "./Loading";
import Missing from "./Missing";

const UserProfile = () => {
    const { t } = useLanguageContext();
    const pageLink = useParams();
    const { coinData } = useContext(CoinContextProvider);

    const [loot, setLoot] = useState();
    const [topUserWinAmount, setTopUserWinAmount] = useState(0);
    const [totalCasesOpenedByUser, setTotalCasesOpenedByUser] = useState(0);
    const [startingTimestamp, setStartingTimestamp] = useState();
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [currentGameTypeStatsMobileScreen, setCurrentGameTypeStatsMobileScreen] = useState('cases');
    const currentGameTypes = ['cases', 'races', 'predictions'];

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        getUserLoot();
        getTopUserWinAmount();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            const currentIndex = currentGameTypes.indexOf(currentGameTypeStatsMobileScreen);
            const nextIndex = (currentIndex + 1) % currentGameTypes.length;
            setCurrentGameTypeStatsMobileScreen(currentGameTypes[nextIndex]);
        }, 4000);

        return () => clearInterval(interval);
    }, [currentGameTypeStatsMobileScreen, currentGameTypes]);

    const getUserLoot = async () => {
        try {
            const response = await axios.get(`/loots/${pageLink.username}`, {
                params: {
                    startingTimestamp
                }
            });
            const moreLoot = response.data.loot
            setLoot(loot ? [...loot, ...moreLoot] : moreLoot)
            setStartingTimestamp(response.data.pagination.nextPage)
        } catch (err) {
            console.error(err);
            setIsLastPage(true)
        } finally {
            setIsLoading(false);
        }
    }

    const getTopUserWinAmount = async () => {
        try {
            const response = await axios.get(`/stats/useroverall/`, {
                params: {
                    username: pageLink.username
                }
            });
            setTopUserWinAmount(response.data.topLoot[0].amount.$numberDecimal)
            setTotalCasesOpenedByUser(response.data.totalCases[0].totalCasesOpened)
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                loot ? (
                    <div className="flex center">
                        <div className="user-overview">
                            <div className="header">
                                <div className="top-block flex column">
                                    <div className="top-half">
                                    </div>
                                    <div className="bottom-half flex row">
                                        <div className="before-avatar"></div>
                                        <div className="under-avatar flex end">
                                            <div className="avatar-part">
                                                <img className="avatar" src="/img/main-content/user-avatar.svg" />
                                            </div>
                                            <div className="background-part"></div>
                                        </div>
                                        <div className="after-avatar flex">
                                            <div className="total-games flex row">
                                                <div className={currentGameTypeStatsMobileScreen === 'cases' ? 'cases' : 'hidden'}>
                                                    <div className="flex row">
                                                        <div className="icon-background">
                                                            <img className="icon-svg" src="/img/user-overview/case-white.svg" />
                                                        </div>
                                                        <div className="flex column">
                                                            <div className="amount">{totalCasesOpenedByUser}</div>
                                                            <div className="type">{t("Текст 1: Профиль пользователя")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={currentGameTypeStatsMobileScreen === 'races' ? 'races' : 'hidden'}>
                                                    <div className="flex row">
                                                        <div className="icon-background">
                                                            <img className="icon-svg" src="/img/user-overview/races-white.svg" />
                                                        </div>
                                                        <div className="flex column">
                                                            <div className="amount">-</div>
                                                            <div className="type">{t("Текст 2: Профиль пользователя")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={currentGameTypeStatsMobileScreen === 'predictions' ? 'predictions' : 'hidden'}>
                                                    <div className="flex row">
                                                        <div className="icon-background">
                                                            <img className="icon-svg" src="/img/user-overview/predictions-white.svg" />
                                                        </div>
                                                        <div className="flex column">
                                                            <div className="amount">-</div>
                                                            <div className="type">{t("Текст 3: Профиль пользователя")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-block flex row space-between">
                                    <div className="nickname">{pageLink.username}</div>
                                    <div className="max-win flex row center">
                                        <div className="title">
                                            <img className="star-icon" src="/img/user-overview/star.svg" />
                                            {t("Текст 4: Профиль пользователя")}
                                        </div>
                                        <div className="sum">${topUserWinAmount}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="top-users-block flex center">
                                <div className="top-users-content">
                                    <div className="top-users-header">
                                        <h3>{t("Заголовок 1: Профиль пользователя")}</h3>

                                        <nav className="top-users-nav">
                                            <div className="top-users-nav-tab-button active">
                                                <h3>{t("Вкладка 1: Профиль пользователя")}</h3>
                                            </div>
                                            <div className="top-users-nav-tab-button disabled">
                                                <h3>{t("Вкладка 2: Профиль пользователя")}</h3>
                                            </div>
                                            <div className="top-users-nav-tab-button disabled">
                                                <h3>{t("Вкладка 3: Профиль пользователя")}</h3>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div className="case-loots flex wrap">
                                {
                                    loot && coinData &&
                                    loot.map(({ coin, amount, caseSlug, _id }) => {
                                        // const targetCoin = coins.find(({ symbol }) => symbol === coin);
                                        // const price = parseFloat(amount.$numberDecimal / targetCoin.latestPrice.$numberDecimal).toFixed(4);
                                        const symbolOfCoin = coinData.find(({ symbol }) => symbol === coin);
                                        const coinColor = symbolOfCoin ? symbolOfCoin.hexColor : '#fff'; // default color
                                        return (
                                            <Link to={`/cases/${caseSlug}`} key={_id}>
                                                <div className="case-loot-item flex column center"
                                                    style={{ borderBottom: `5px solid ${coinColor}` }}
                                                >
                                                    <img src={`/images/logos/${coin.toLowerCase()}.png`} />
                                                    <p><b>${parseFloat(amount.$numberDecimal).toFixed(2)}</b></p>
                                                    <p>{coin}</p>
                                                    <div
                                                        className="gradient-overlay"
                                                        style={{
                                                            background: `linear-gradient(to bottom, #232332 0%, ${coinColor} 100%)`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </Link>
                                        );
                                    })
                                }
                            </div>

                            {!isLastPage && (
                                <div className="flex center">
                                    <button className="more" onClick={getUserLoot}>
                                        {t("Кнопка 1: Профиль пользователя")}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <Missing />
                )
            )}
        </>
    );

}

export default UserProfile