import { Outlet } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast";
import Header from "./Header"
import Footer from "./Footer"

const Layout = () => {
    return (
        <main className="App">
            <Toaster
                toastOptions={{
                    duration: 7000,
                    position: 'bottom-center',
                    icon: false,
                    style: {
                        zIndex: 9999,
                        background: "#fff",
                        color: "#333",
                        boxShadow: "none",
                    },
                    error: {
                        style: {
                            backgroundColor: "#FF685F",
                            color: "#fff"
                        }
                    },
                }}
                containerStyle={{
                    bottom: 50,
                }}
                containerClassName="toasts"
            />
            <Header />
            <Outlet />
            <Footer />
        </main>
    )
}

export default Layout
