// convert all images to jpeg (best)
// use heic2any library to convert iphone photos (?)
// test formats (png, webp) write and read from bucket (?)

import { useRef, useState, useContext, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { UserContextProvider } from "../context/UserContextProvider";
import { useLanguageContext } from "../context/languageContext";
import toast from "react-hot-toast";
import CloseModalIcon from "../assets/svg/CloseModalIcon";
import useDetectOutsideClick from "../hooks/useDetectOutsideClick";
import Compressor from 'compressorjs';

const DATE_REGEX = /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;
const CYRILLIC_LETTERS_REGEX = /^[а-яА-ЯЁё\s]*$/;
const LATIN_LETTERS_REGEX = /^[a-zA-Z]*$/;

const TryKYC = ({ isTryKYCModalVisible, handleShowActivateKYCModal }) => {
    const modalRef = useRef(null);
    const axiosPrivate = useAxiosPrivate();
    const { t } = useLanguageContext();
    const { userData, updateUserData } = useContext(UserContextProvider);

    const [isLatinDocument, setIsLatinDocument] = useState(true);
    const [nameRus, setNameRus] = useState('');
    const [isValidNameRus, setIsValidNameRus] = useState(true);
    const [surnameRus, setSurnameRus] = useState('');
    const [isValidSurnameRus, setIsValidSurnameRus] = useState(true);
    const [nameEng, setNameEng] = useState('');
    const [isValidNameEng, setIsValidNameEng] = useState(true);
    const [surnameEng, setSurnameEng] = useState('');
    const [isValidSurnameEng, setIsValidSurnameEng] = useState(true);
    const [birthday, setBirthday] = useState('');
    const [isValidBirthday, setIsValidBirthday] = useState(false);
    const [isSecondStep, setIsSecondStep] = useState(false);
    const [photoDocumentOptimized, setPhotoDocumentOptimized] = useState(null);
    const [photoSelfieOptimized, setPhotoSelfieOptimized] = useState(null);
    const [photoDocumentStatus, setPhotoDocumentStatus] = useState(null);
    const [photoDocumentError, setPhotoDocumentError] = useState(false);
    const [photoSelfieStatus, setPhotoSelfieStatus] = useState(null);
    const [photoSelfieError, setPhotoSelfieError] = useState(false);
    const [imageUrl, setImageUrl] = useState(''); // TEMP

    useDetectOutsideClick(modalRef, () => {
        handleCloseTryKYCModal();
    });

    useEffect(() => {
        setIsValidNameRus(CYRILLIC_LETTERS_REGEX.test(nameRus));
    }, [nameRus])

    useEffect(() => {
        setIsValidSurnameRus(CYRILLIC_LETTERS_REGEX.test(surnameRus));
    }, [surnameRus])

    useEffect(() => {
        setIsValidNameEng(LATIN_LETTERS_REGEX.test(nameEng));
    }, [nameEng])

    useEffect(() => {
        setIsValidSurnameEng(LATIN_LETTERS_REGEX.test(surnameEng));
    }, [surnameEng])

    useEffect(() => {
        setIsValidBirthday(DATE_REGEX.test(birthday));
    }, [birthday])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack

        const birthdayFormattedISO = await formatBirthdayToISO(birthday);

        const formData = new FormData();
        formData.append('nameRus', nameRus);
        formData.append('surnameRus', surnameRus);
        formData.append('nameEng', nameEng);
        formData.append('surnameEng', surnameEng);
        formData.append('dateOfBirthISO', birthdayFormattedISO);
        formData.append('photoDocument', photoDocumentOptimized);
        formData.append('photoSelfie', photoSelfieOptimized);

        try {
            const response = await axiosPrivate.put('/me/trykyc', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });
            updateUserData();
            toast.success("KYC form successfully sent. Please, wait for moderation.")
            handleCloseTryKYCModal();
        } catch (err) {
            if (!err?.response) {
                toast.error('No Server Response');
            } else if (err.response?.status === 400) {
                toast.error(`${JSON.stringify(err.response?.data.message).replace(/"/g, '')}`);
            } else {
                toast.error('KYC process failed. Please, contact support.')
                console.log(err.response)
            }
        }
    }

    const handlePhotoDocumentUpload = async (e) => {
        try {
            const formData = await handlePhotoUpload(e);
            setPhotoDocumentOptimized(formData);
            setPhotoDocumentError(false);
            setPhotoDocumentStatus('✓');
        } catch (error) {
            setPhotoDocumentError(true);
            setPhotoDocumentOptimized(null);
            setPhotoDocumentStatus(t("Текст 8: KYC"));
            console.error(error);
        }
    };

    const handlePhotoSelfieUpload = async (e) => {
        try {
            const formData = await handlePhotoUpload(e);
            setPhotoSelfieOptimized(formData);
            setPhotoSelfieError(false);
            setPhotoSelfieStatus('✓');
        } catch (error) {
            setPhotoSelfieError(true);
            setPhotoSelfieOptimized(null);
            setPhotoSelfieStatus(t("Текст 8: KYC"));
            console.error(error);
        }
    };

    const handlePhotoUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) throw new Error('No file selected');
        const maxSize = 10 * 1024 * 1024; // 10MB
        if (file.size > maxSize) {
            throw new Error('File size exceeds limit');
        } else {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: 0.6,
                    success(result) {
                        // setImageUrl(URL.createObjectURL(result)); // test output
                        resolve(result);
                    },
                    error(err) {
                        reject(err.message);
                    },
                });
            });
        }
    };

    const formatBirthdayToISO = async (birthday) => {
        const parts = birthday.split('.'); // input format 25.01.1999
        const birthdayISO = `${parts[2]}-${parts[1]}-${parts[0]}`;
        return birthdayISO; // output format 1999-01-25
    }

    const handleCloseTryKYCModal = () => {
        handleShowActivateKYCModal(false);
    };

    return (
        <>
            {isTryKYCModalVisible ? (
                <div className="any-modal-background">
                    <div className="any-modal flex column" ref={modalRef}>
                        <div className="flex space-between">
                            <h1>{t("Заголовок 1: KYC")}</h1>
                            <div className="close-modal-icon" onClick={handleCloseTryKYCModal}><CloseModalIcon /></div>
                        </div>
                        <form className="try-kyc" onSubmit={handleSubmit}>
                            {!isSecondStep &&
                                <>
                                    <p>{t("Заголовок 2: KYC")}</p>
                                    <div className="document-language-switch flex row">
                                        <button disabled={isLatinDocument} onClick={() => setIsLatinDocument(true)} className="micro">{t("Кнопка 1: KYC")}</button>
                                        <button disabled={!isLatinDocument} onClick={() => setIsLatinDocument(false)} className="micro">{t("Кнопка 2: KYC")}</button>
                                    </div>

                                    <p>{t("Заголовок 3: KYC")}</p>
                                    <label htmlFor="name"></label>
                                    <input
                                        className="caps-lock"
                                        type="text"
                                        id="name"
                                        autoComplete="off"
                                        onChange={(e) => isLatinDocument ? setNameEng(e.target.value.toUpperCase()) : setNameRus(e.target.value.toUpperCase())}
                                        value={isLatinDocument ? nameEng : nameRus}
                                        placeholder={isLatinDocument ? "JOHN" : "ИВАН"}
                                    />

                                    <label htmlFor="symbols-check">
                                        {isLatinDocument ?
                                            (isValidNameEng || !nameEng ? null : <div className="input-assist">{t("Текст 1: KYC")}</div>)
                                            :
                                            (isValidNameRus || !nameRus ? null : <div className="input-assist">{t("Текст 2: KYC")}</div>)
                                        }
                                    </label>

                                    <p>{t("Заголовок 4: KYC")}</p>
                                    <label htmlFor="surname"></label>
                                    <input
                                        className="caps-lock"
                                        type="text"
                                        id="surname"
                                        autoComplete="off"
                                        onChange={(e) => isLatinDocument ? setSurnameEng(e.target.value.toUpperCase()) : setSurnameRus(e.target.value.toUpperCase())}
                                        value={isLatinDocument ? surnameEng : surnameRus}
                                        placeholder={isLatinDocument ? "SMITH" : "ИВАНОВ"}
                                    />

                                    <label htmlFor="symbols-check">
                                        {isLatinDocument ?
                                            (isValidSurnameEng || !surnameEng ? null : <div className="input-assist">{t("Текст 1: KYC")}</div>)
                                            :
                                            (isValidSurnameRus || !surnameRus ? null : <div className="input-assist">{t("Текст 2: KYC")}</div>)
                                        }
                                    </label>

                                    <p>{t("Заголовок 5: KYC")}</p>
                                    <label htmlFor="birthday"></label>
                                    <input
                                        className="caps-lock"
                                        type="text"
                                        id="birthday"
                                        autoComplete="off"
                                        onChange={(e) => setBirthday(e.target.value)}
                                        value={birthday}
                                        placeholder={"12.01.1992"}
                                        maxLength="10"
                                    />
                                    <label htmlFor="birthday-check">
                                        {isValidBirthday || !birthday ? null : <div className="input-assist">{t("Текст 3: KYC")}</div>}
                                    </label>

                                    <span className="agreement-note">{t("Текст 4: KYC")}</span>
                                    <button
                                        type="button"
                                        className="register"
                                        disabled={!((nameRus && isValidNameRus && surnameRus && isValidSurnameRus && isValidBirthday) || (nameEng && isValidNameEng && surnameEng && isValidSurnameEng && isValidBirthday))}
                                        onClick={() => setIsSecondStep(true)}
                                    >
                                        {t("Кнопка 3: KYC")}
                                    </button>
                                </>
                            }
                            {isSecondStep &&
                                <>
                                    <div className="backlink">
                                        <a href="#" className="back" onClick={() => setIsSecondStep(false)}>{t("Ссылка 1: KYC")}</a>
                                    </div>

                                    <p>{t("Заголовок 6: KYC")}</p>
                                    <p className="agreement-note">{t("Текст 6: KYC")}</p>
                                    <div className="custom-file-input">
                                        <input type="file" className={`${photoDocumentError ? 'error' : (photoDocumentStatus && 'success')}`} accept="image/*" onChange={handlePhotoDocumentUpload} />
                                        {!photoDocumentStatus ?
                                            <p className="text">{t("Текст 7: KYC")}</p> : <p className="text">{photoDocumentStatus}</p>
                                        }
                                    </div>

                                    <p>{t("Заголовок 7: KYC")}</p>

                                    <a href="/img/help/kyc-example.jpg" target="_blank" rel="noopener">
                                        <button className="micro example" type="button">{t("Кнопка 5: KYC")}</button>
                                    </a>

                                    <div className="custom-file-input">
                                        <input type="file" className={`${photoSelfieError ? 'error' : (photoSelfieStatus && 'success')}`} accept="image/*" onChange={handlePhotoSelfieUpload} />
                                        {!photoSelfieStatus ?
                                            <p className="text">{t("Текст 7: KYC")}</p> : <p className="text">{photoSelfieStatus}</p>
                                        }
                                    </div>

                                    <button type="submit" disabled={!photoDocumentOptimized || !photoSelfieOptimized}>{t("Кнопка 6: KYC")}</button>
                                </>
                            }
                        </form>
                    </div>
                </div>
            ) : (
                null
            )}
        </>
    )
}

export default TryKYC