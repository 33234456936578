import { useState } from "react";
import { useLanguageContext } from "../context/languageContext";

const Help = () => {
    const { t } = useLanguageContext();
    const helpTexts = [
        { title: t("Заголовок 1: Помощь"), text: t("Текст 1: Помощь") },
        { title: t("Заголовок 2: Помощь"), text: t("Текст 2: Помощь") },
        { title: t("Заголовок 3: Помощь"), text: t("Текст 3: Помощь") },
    ];

    const initialVisibility = helpTexts.map(() => false);
    const [isTextBlockVisible, setIsTextBlockVisible] = useState(initialVisibility);

    const toggleTextBlock = (index) => {
        setIsTextBlockVisible((prev) =>
            prev.map((item, itemIndex) => (itemIndex === index ? !item : item))
        );
    };

    return (
        <div className="help-block flex center column">
            <h1>{t("Заголовок 0: Помощь")}</h1>
            {helpTexts.map((item, index) => (
                <div key={index}>
                    <div className={`question-block flex column ${isTextBlockVisible[index] ? 'active' : ''}`}>
                        <div className="question-header flex space-between" onClick={() => toggleTextBlock(index)}>
                            <div className="left-part flex row">
                                <div className="question-icon-block flex center">
                                    <div className="question-icon flex center">
                                        <img src="/img/help/question.svg" />
                                    </div>
                                </div>
                                <p className="flex center">{item.title}</p>
                            </div>
                            <div className="right-part flex row">
                                <div className="arrow-icon-block flex center">
                                    <div className="arrow-icon flex center">
                                        <img src="/img/help/arrow.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isTextBlockVisible[index] && <div className="text-block">{item.text}</div>}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Help